// Import of packages
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Import of components

// Import of views

// Import of context, functions, hook ect...
import userAdminService from '../../shared/api/services/userAdminService';
import { serverError } from '../../shared/function/function';

// Import of images
import x from '../../shared/images/x.png';

// Config

// Imoprt of CSS, SASS
import './snackbar.css';

const Snackbar = (props: { content: any }) => {
  // Destruct props
  const { content } = props;

  // State and hooks
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    content.title && setShowSnackbar(true);
  }, []);

  // Variables
  const closeIcon = <img src={x} alt={'Ta bort'} style={{ height: '20px' }} />;

  // Functions
  const closeForEver = async () => {
    try {
      await userAdminService.deleteMessage(content.id);
      setShowSnackbar(false);
    } catch (error: any) {
      console.log(error, error.response);
      // error to null
      if (error.response === undefined) {
        serverError();
      } else {
        error.log(error);
      }
    }
  };

  // Render
  return (
    <div className="snackbar-container" id={showSnackbar ? 'show' : 'hide'}>
      <h3
        style={{
          margin: '10px 0 20px 0',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {content?.title && content?.title}
        <span
          style={{
            fontWeight: 'bold',
            position: 'relative',
            top: '-12px',
            cursor: 'pointer',
          }}
          onClick={() => closeForEver()}
          title={t('components.snackbar.close', 'Stäng')}
        >
          {closeIcon}
        </span>
      </h3>
      <div className="snackbar-message">
        {content?.message && content?.message}
      </div>
    </div>
  );
};

export default Snackbar;
