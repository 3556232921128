import React, { useState, createContext, useEffect, useContext } from 'react';
import userAdminService from '../api/services/userAdminService';
import { loadingMarkup } from '../function/function';
import KeycloakService from './KeycloakService';
// service
import inSightService from '../api/services/inSightService';
import { TenantContext } from './TenantProvider';

export const UserDetailsContext = createContext<any>(null);

export const UserDetailsProvider = (props: { children?: React.ReactChild }) => {
  const { children } = props;

  const [userDetails, setUserDetails] = useState<any>();
  const [error, setError] = useState<any>(null);

  //
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);

  const [integrityStatistics, setIntegrityStatistics] = useState<any>();
  const [securityStatistics, setSecurityStatistics] = useState<any>();
  const [reviewCounter, setReviewCounter] = useState<any>();
  const { tenantProducts } = useContext<any>(TenantContext);

  useEffect(() => {
    const id = KeycloakService.getUserDbId();
    id && fetchUserDetails(id);
  }, []);

  const fetchUserDetails = async (id: string) => {
    try {
      const { data } = await userAdminService.userDetails(id);

      setUserDetails(data.result);
    } catch (error: any) {
      console.log(error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        console.log(error, error.response);

        console.error(error);
      } else {
        setError(error.response);
      }
    }
  };

  useEffect(() => {
    userDetails && userPermission.length !== 0 && getStatistics();
  }, [userDetails]);

  let userPermission = userDetails?.groups.map((g: any) => {
    return {
      group: g.group.name,
      permissions: g.role.permissions,
    };
  });

  if (userDetails) {
    // add dmin role to userPermission
    let adminRole = {
      group: userDetails.adminRole.name,
      permissions: userDetails.adminRole.permissions,
    };

    userPermission = userPermission.concat(adminRole);
  }

  // get statistics - put in this context, to make api calls during animation
  const getStatistics = () => {
    // only fetch if you have access
    tenantProducts &&
      tenantProducts.includes('dpia') &&
      fetchStatisticsIntegrity();

    tenantProducts &&
      tenantProducts.includes('security') &&
      fetchStatisticsSecurity();

    tenantProducts &&
      tenantProducts.includes('integrity') &&
      fetchReviewCounter();
  };

  const fetchStatisticsIntegrity = async () => {
    setIsLoadingChart(true);
    try {
      const data = await inSightService.getStatisticsIntegrity();
      setIntegrityStatistics(data?.data.result);
    } catch (error: any) {
      console.log(error);
      setError(null);
      if (error.response === undefined) {
        console.log(error);
      } else {
        setError(error.response);
      }
    }
    setIsLoadingChart(false);
  };

  const fetchStatisticsSecurity = async () => {
    setIsLoadingChart(true);
    try {
      const data = await inSightService.getStatisticsSecurity();
      setSecurityStatistics(data?.data.result);
    } catch (error: any) {
      console.log(error);

      setError(null);
      if (error.response === undefined) {
        console.log(error);
      } else {
        setError(error.response);
      }
    }
    setIsLoadingChart(false);
  };

  const fetchReviewCounter = async () => {
    setIsLoading(true);
    try {
      const data = await inSightService.getStatisticsReviewCounter();
      setReviewCounter(data?.data.result);
    } catch (error: any) {
      console.log(error);

      setError(null);
      if (error.response === undefined) {
        console.log(error);
      } else {
        setError(error.response);
      }
    }
    setIsLoading(false);
  };

  const state = {
    userDetails,
    setUserDetails,
    userPermission,
    isLoading,
    isLoadingChart,
    integrityStatistics,
    securityStatistics,
    reviewCounter,
  };

  return (
    <UserDetailsContext.Provider value={state}>
      {userDetails ? children : loadingMarkup}
    </UserDetailsContext.Provider>
  );
};
