// integrity
const integrityAccess = ['PERSONALDATAPROCESS_READ'];
const newPersonalDataProcessingViewAccess = ['PERSONALDATAPROCESS_CREATE'];
const indexListViewAccess = [
  'CONTROLLER_READ',
  'INFORMATIONCARRIER_READ',
  'QUESTION_READ',
  'PERSONALDATAPROCESS_READ',
];
const dashboardViewAccess = ['PERSONALDATAPROCESS_READ'];
const investigateAccess = ['INVESTIGATION_READ'];
const dpiaAccess = ['DPIA_READ'];
// security
const securityAccess = ['SECURITY_READ'];
const documentationICAccess = ['SECURITY_READ'];
const riskICAccess = ['SECURITY_READ'];
// admin
const userAdminAccess = [ 'ROLE_READ', 'GROUP_READ'];
// const userAdminAccess = ['USER_UPDATE', 'ROLE_UPDATE', 'GROUP_UPDATE','USER_CREATE', 'ROLE_CREATE', 'GROUP_CREATE'];

const appAdminAccess = [
  'EVENTLOG_READ',
  'CONTROLLER_READ',
  'INFORMATIONCARRIER_READ',
  'QUESTION_READ',
  'CUSTOMISATION_READ',
];
const settings = [
  'EVENTLOG_READ',
  'CONTROLLER_READ',
  'INFORMATIONCARRIER_READ',
  'QUESTION_READ',
  'CUSTOMISATION_READ',
  'USER_READ',
];

const icPageViewAccess = ['SECURITY_READ'];

const securityFramework = ['FRAMEWORK_READ'];

const insights = ['DASHBOARD_READ'];

export default {
  integrityAccess,
  newPersonalDataProcessingViewAccess,
  indexListViewAccess,
  dashboardViewAccess,
  investigateAccess,
  dpiaAccess,
  securityAccess,
  documentationICAccess,
  riskICAccess,
  userAdminAccess,
  appAdminAccess,
  icPageViewAccess,
  settings,
  securityFramework,
  insights
};
