// import of packages
import { StrictMode, useContext, useEffect, useState, Suspense } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import { BrowserRouter } from 'react-router-dom';
import { locizePlugin } from 'locize';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// import of components, context ect
import { Navigation } from './components/navigation/Navigation';
import Snackbar from './components/snackbar/Snackbar';

// import of roues
import { RouterFile } from './routes/Routes';

// import of context, service ect
import { ActiveModeProvider } from './shared/provider/ActiceModeProvider';
import { DataProvider } from './shared/provider/DataProvider';
import { UserDetailsContext } from './shared/provider/UserDetailsProvider';
import { UserProvider } from './shared/provider/UserProvider';
import { loadingMarkup } from './shared/function/function';

import userAdminService from './shared/api/services/userAdminService';
import { serverError } from './shared/function/function';
import LanguageController from './LanguageController';
import { DateTime } from 'luxon';
import EditProfileKeycloak from './views/AppAdmin/EditProfileKeycloak';
import KeycloakService from './shared/provider/KeycloakService';

//imports of css
import './views/IntegrityModule/integrity.css';
import './views/view.css';
import './App.css';
import './OverrideMui.css';
import './components/formInput/formInput.css';
// Promblems with css for Datepicker, import at load, to get fixes on all copmponent/views
import 'react-datepicker/dist/react-datepicker.css';
import '../node_modules/quill/dist/quill.snow.css';

let globalUserDetails;
let globalUserPermission;

function App() {
  const [notification, setNotification] = useState([]);
  const [language, setLanguage] = useState();
  const { userDetails, userPermission } = useContext(UserDetailsContext);

  useEffect(() => {
    fetchNotification();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setLanguage(userDetails.locale);
    }
  }, [userDetails]);

  const fetchNotification = async () => {
    try {
      const data = await userAdminService.getMessage();
      if (data?.data.result.length !== 0) {
        setNotification(data?.data.result[0]);
      }
    } catch (error) {
      console.log(error, error.response);

      // error to null
      if (error.response === undefined) {
        serverError();
      } else {
        console.log(error, error.response);

        error.log(error);
      }
    }
  };

  const isProduction = process.env.NODE_ENV === 'production';
  const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
    apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
    referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
    version: process.env.REACT_APP_LOCIZE_VERSION,
  };

  i18n
    .use(locizePlugin)
    .use(Backend)
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false, // isProduction ,
      lng: language,
      fallbackLng: 'sv',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        // format: (value, format, lng) => { // legacy usage
        //   if (value instanceof Date) {
        //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
        //   }
        //   return value;
        // }
      },
      backend: locizeOptions,
      locizeLastUsed: locizeOptions,
      saveMissing: !isProduction, // you should not use saveMissing in production
    });
  // new usage
  i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
    return DateTime.fromJSDate(value)
      .setLocale(lng)
      .toLocaleString(DateTime.DATE_HUGE);
  });
  globalUserDetails = userDetails;

  globalUserPermission = userPermission;

  const dirsysTheme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#00b388',
        dark: '#009975', // hover
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#c7c7c7',
        dark: '#ADADAD',
        contrastText: '#fff',
      },
      error: {
        main: '#e40909e7', // change the error color to pink
      },
    },
  });

  // Variables
  const logout = KeycloakService.doLogout;

  // for dev
  const bgColor = window.location.href.includes('localhost') ? '#ff7961' : '';

  return (
    <Suspense fallback={loadingMarkup}>
      <StrictMode>
        <ThemeProvider theme={dirsysTheme}>
          <BrowserRouter>
            <UserProvider>
              <DataProvider>
                <ActiveModeProvider>
                  <Navigation />
                  {notification?.title && <Snackbar content={notification} />}
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '5px 25px',
                      alignItems: 'center',
                      margin: '16px',
                      background: bgColor,
                    }}
                  >
                    <LanguageController
                      setLanguage={setLanguage}
                      language={language}
                      userId={userDetails.dbId}
                    />
                    <EditProfileKeycloak logout={logout} />
                  </span>
                  <RouterFile userDetails={userDetails}></RouterFile>
                </ActiveModeProvider>
              </DataProvider>
            </UserProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StrictMode>
    </Suspense>
  );
}

export { App, globalUserDetails, globalUserPermission };
