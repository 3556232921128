// Import of packages

// Import of components

// Import of views

// Import of context, functions, hook ect...

// Import of images

// Config

// Imoprt of CSS, SASS
import './button.css';

export const Button = (props: {
  buttonText: any;
  onClick?: any;
  disabled?: boolean;
  customColor?: string;
  onSave?: boolean;
  smallButton?: any;
  addCusoumClass?: string;
  addStyle?: any;
}) => {
  // Destruct props
  const {
    buttonText,
    onClick,
    disabled,
    customColor,
    onSave,
    smallButton,
    addCusoumClass,
    addStyle,
  } = props;

  // State and hooks

  // Variables

  // Functions

  // Render
  return (
    <span
      className={
        onSave
          ? 'onSave'
          : smallButton
          ? 'small-button-controller'
          : disabled
          ? 'btn no-hover'
          : 'btn'
      }
    >
      <button
        className={addCusoumClass}
        data-cy={`button-testId-${
          typeof buttonText === 'string'
            ? buttonText.replace(/\s+/g, '')
            : 'img'
        }`}
        id={customColor}
        disabled={disabled}
        onClick={onClick}
        style={addStyle}
      >
        {buttonText}
      </button>
    </span>
  );
};
