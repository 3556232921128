import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../modal.css';

const FunctionalPopUp = (props: {
  children?: any;
  trigger: boolean;
  noOverlay?: boolean;
}) => {
  const modalDiv = document.getElementById('modal')!;

  // prevent scroll on page when page is open
  useEffect(() => {
    window.onscroll = function () {
      window.scrollTo(0, 0);
    };
  }, [props]);

  return props.trigger
    ? ReactDOM.createPortal(
        <div data-cy="modal">
          <div className={`top`}>{props.children}</div>
          <div className={props.noOverlay ? '' : `overlay-no-click`}></div>
        </div>,
        modalDiv
      )
    : null;
};

export default FunctionalPopUp;
