// Import of packages
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import of components
import { Button } from '../components/button/Button';

// Config
import RoutingPath from './RoutingPath';

export const Forbidden = () => {
  // State and hooks
  const navigate = useNavigate();
  // lng
  const { t } = useTranslation();

  // Render
  return (
    <div className="wrapper container" style={{ height: '100vh' }}>
      <h1>{t('routes.forbidden.notAllowedTitle', 'Behörigheter saknas')}</h1>
      <div style={{ textAlign: 'center', margin: '20px' }}>
        <p style={{ marginBottom: '10px' }}>
          {t(
            'routes.forbidden.description',
            'Du saknar behörigheter. Var god kontakta er administratör för utökade rättigheter.'
          )}
        </p>
      </div>
      <Button
        buttonText={t('routes.forbidden.buttonToStartPage', 'Till startsidan')}
        onClick={() => navigate(RoutingPath.WelcomeScreen)}
      />
    </div>
  );
};

export default Forbidden;
