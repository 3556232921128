import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import userAdminService from './shared/api/services/userAdminService';
import { colourStyles, serverError } from './shared/function/function';
import Select from 'react-select';

const LanguageController = (props: {
  setLanguage: any;
  language: any;
  userId: string;
}) => {
  const { setLanguage, language, userId } = props;
  const [error, setError] = useState(null);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setLanguage(language);
    i18n.changeLanguage(language);
  }, []);

  const handleLangChange = async (e: any) => {
    const lang = e.lang;
    setLanguage(lang);
    i18n.changeLanguage(lang);
    try {
      await userAdminService.putLocale(userId, lang);
    } catch (error: any) {
      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const options = [
    { lang: 'sv', name: t('components.languageController.sv', 'Svenska') },
    { lang: 'en', name: t('components.languageController.en', 'Engelska') },
  ];

  return (
    <>
      <Select
        isDisabled={false}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.lang}
        closeMenuOnSelect={true}
        classNamePrefix="react-select"
        className="react-select-container select-use-for-language"
        isMulti={false}
        isSearchable
        placeholder={t(
          'components.languageController.placeholder',
          'Välj språk'
        )}
        options={options}
        styles={colourStyles}
        onChange={handleLangChange}
        value={options.filter((o) => {
          return o.lang === language;
        })}
      />
    </>
  );
};

export default LanguageController;
