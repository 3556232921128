import React, { useState, createContext, useEffect } from 'react';
import { loadingMarkup } from '../function/function';
import KeycloakService from './KeycloakService';
import tenantService from '../api/services/tenantService';
export const TenantContext = createContext<any>(null);

export const TenantProvider = (props: { children?: React.ReactChild }) => {
  const { children } = props;

  const [tenantInformation, setTenantInformation] = useState<string>('');
  const [tenantProducts, setTenantProducts] = useState<
    { id: number; name: string }[]
  >([]);
  const [error, setError] = useState<any>(null);

  const getTenantInformation = async () => {
    try {
      const data = await tenantService.getTenantInformation(
        KeycloakService.getTenant()
      );
      setTenantInformation(data?.data.result);
      const products = data?.data.result.productList;
      setTenantProducts(
        products.lengte !== 0
          ? products.map((product: any) => product.name)
          : []
      );
    } catch (error: any) {
      // error to null
      setError(null);
    }
  };

  useEffect(() => {
    getTenantInformation();
  }, []);

  const state = {
    tenantInformation,
    tenantProducts,
  };

  return (
    <TenantContext.Provider value={state}>
      {tenantInformation ? children : loadingMarkup}
    </TenantContext.Provider>
  );
};
