// Import of packages
import { useState } from 'react';

// Imoprt of CSS, SASS
import './loadingDirsys.css';

const LoadingDirsys = () => {
  // State and hooks
  const [showD, setShowD] = useState<boolean>(false);
  const [showI, setShowI] = useState<boolean>(false);
  const [showR, setShowR] = useState<boolean>(false);
  const [showS, setShowS] = useState<boolean>(false);
  const [showY, setShowY] = useState<boolean>(false);
  const [showSS, setShowSS] = useState<boolean>(false);

  // Functions
  const title = [
    { letter: 'D', func: showD },
    { letter: 'i', func: showI },
    { letter: 'r', func: showR },
    { letter: 'S', func: showS },
    { letter: 'y', func: showY },
    { letter: 's', func: showSS },
  ];

  // Render
  return (
    <ul
      className="center-everything loading-dirsys"
      style={{ height: '100vh', listStyle: 'none' }}
    >
      {title.map((letter, idx, func) => {
        setTimeout(() => {
          setShowD(true);
        }, 0);
        setTimeout(() => {
          setShowI(true);
        }, 150);
        setTimeout(() => {
          setShowR(true);
        }, 300);
        setTimeout(() => {
          setShowS(true);
        }, 550);
        setTimeout(() => {
          setShowY(true);
        }, 800);
        setTimeout(() => {
          setShowSS(true);
        }, 1050);
        return (
          <li key={idx} className={`${letter.func ? 'show' : ''}`}>
            {letter.letter}
          </li>
        );
      })}
    </ul>
  );
};

export default LoadingDirsys;
