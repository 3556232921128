// Import of components
import { SidebarNavigation } from './SidebarNavigation';

// Imoprt of CSS, SASS
import './navigation.css';

// Render
export const Navigation = () => {
  return (
    <>
      <SidebarNavigation />
    </>
  );
};
