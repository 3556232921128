// Import of packages
import axios from 'axios';
import KeycloakService from '../provider/KeycloakService';

//stratsys integration
import stratsysService from './services/stratsysService';

// Variables
const dev = 'http://localhost:8090/api/v1/';
const devConnectedToCloud = `https://api-developer.dirsys.app/api/v1/`;
//const newUrlProd = `https://api-${JSON_CONFIG.realm}.dirsys.app/api/v1/`;
const newUrlProd = `https://api.dirsys.app/api/v1/`;

const qaUrlProd = `https://api-qa.dirsys.app/api/v1/`;
//for stratys integration
const stratsysApi = 'https://www.stratsys.se/api/v1/';

const betaUrl = `https://api-beta.dirsys.app/api/v1/`;

const tenant = KeycloakService.getTenant();
console.log(tenant);
// /docker

const http = axios.create({
  baseURL: window.location.href.includes('localhost')
    ? devConnectedToCloud //ÄNDRAT
    : window.location.href.includes('beta')
    ? betaUrl
    : window.location.href.includes('jueng.dirsys.app')
    ? newUrlProd
    : window.location.href.includes('eng.dirsys.app')
    ? betaUrl
    : window.location.href.includes('tenant')
    ? devConnectedToCloud
    : window.location.href.includes('-qa')
    ? qaUrlProd
    : window.location.href.includes('dirsys.app')
    ? newUrlProd
    : null,
  headers: {
    'Content-Type': 'application/json',
    // docker
    tenantId: tenant,
  },
});

const httpStratsys = axios.create({
  baseURL: stratsysApi,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set the AUTH token for any request
const configure = () => {
  http.interceptors.request.use(function (config) {
    if (KeycloakService.isLoggedIn()) {
      const cb = () => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;

          return Promise.resolve(config);
        }
      };
      return KeycloakService.updateToken(cb);
    }
  });

  //only for stratsys integration
  httpStratsys.interceptors.request.use(function (config) {
    if (KeycloakService.isLoggedIn()) {
      const cb = async () => {
        if (config.headers) {
          let token = localStorage.getItem('stratsysToken');
          let tenant = localStorage.getItem('stratsysTenant');
          const tokenExpiration = localStorage.getItem(
            'stratsysTokenExpiration'
          );

          if (
            token === null ||
            tokenExpiration === null ||
            Date.now() >= parseInt(tokenExpiration) ||
            token === '[object Promise]' ||
            token === '[object Object]'
          ) {
            await stratsysService.getToken().then((res) => {
              const data = JSON.parse(res.data.result);
              token = data.token_type + ' ' + data.access_token;
            });
            const newExpiration = Date.now() + 3600000;
            localStorage.setItem(
              'stratsysTokenExpiration',
              newExpiration.toString()
            );
          }

          if (tenant === null) {
            tenant = await stratsysService.getTenant();
          }
          if (token != null && tenant != null) {
            config.headers.Authorization = token;
            localStorage.setItem('stratsysToken', token);
            config.headers['Stratsys-CompanyCode'] = tenant;
            localStorage.setItem('stratsysTenant', tenant);
            return Promise.resolve(config);
          }
        }
      };
      return KeycloakService.updateToken(cb);
    }
  });
};
export { configure, http, httpStratsys };
