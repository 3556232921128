// Import of packages

// Import of components

// Import of views

// Import of context, functions, hook ect...

// Import of images
import bgIcon from './images/2.png';
import arrowIcon from './images/3.png';

// Config

// Imoprt of CSS, SASS
import './spinnerDirsys.css';

const SpinnerDirsys = ({ msg = '' }) => {
  //Hooks

  // Render
  return (
    <>
      <div className="center">
        <div
          style={{
            backgroundImage: `url(${bgIcon})`,
            backgroundRepeat: 'no-repeat',
            width: '50px',
            height: '50px',
          }}
        >
          <div style={{ position: 'relative', left: '0', top: '0' }}>
            <img
              className="spin"
              src={arrowIcon}
              style={{ position: 'relative', top: '-2px', left: '15px' }}
              alt="Loading icon"
            />
          </div>
        </div>
      </div>
      <p className="center-everything" style={{ marginTop: '-5px' }}>
        {msg}
      </p>
    </>
  );
};

export default SpinnerDirsys;
