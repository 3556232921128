// Import of packages
import { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';

// Import of context, functions, hook ect...
import ProtectedRoute from './ProtectedRoute';
import AccessControll from '../shared/Permissions/AccessControll';
import KeycloakService from '../shared/provider/KeycloakService';
import { loadingMarkup } from '../shared/function/function';
import LoadingDirsys from '../views/LoadingDirsys';

// Config
import RoutingPath from './RoutingPath';
import { TenantContext } from '../shared/provider/TenantProvider';
import ResetPassword from 'src/views/ResetPassword';

// Import of views - lazy
const DashboardView = lazy(() => import('../views/Dashboard/DashboardView'));
const WelcomeScreen = lazy(() => import('../views/WelcomeScreen'));
const Forbidden = lazy(() => import('./Forbidden'));
const ErrorView = lazy(() => import('../views/ErrorView'));
const IndexListView = lazy(
  () => import('../views/IntegrityModule/IndexListView')
);
const NewPersonalDataProcessingView = lazy(
  () => import('../views/IntegrityModule/NewPersonalDataProcessingView')
);
const WrapperAdmin = lazy(() => import('../views/AppAdmin/WrapperAdmin'));

const Dpia = lazy(() => import('../views/IntegrityModule/DPIA/Dpia'));
const InvestigateDpia = lazy(
  () => import('../views/IntegrityModule/DPIA/InvestigateDpia')
);
const ServerIsDownView = lazy(() => import('../views/ServerIsDownView'));
const IcPageView = lazy(() => import('../views/SecurityModule/IcPageView'));
const WrapperDetailedInformationCarrier = lazy(
  () => import('../views/SecurityModule/WrapperDetailedInformationCarrier')
);
const WrapperUserAdmin = lazy(
  () => import('../views/UserAdmin/WrapperUserAdmin')
);
const SecurityFramework = lazy(
  () => import('../views/SecurityFramework/FrameworkWrapper')
);

export const RouterFile = (props: {
  children?: React.ReactChild;
  logout: any;
  userDetails: any;
}) => {
  // Destruct props
  const { children, userDetails } = props;
  const { tenantProducts } = useContext<any>(TenantContext);

  // State and hooks
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  // Variables
  const logout = KeycloakService.doLogout;

  // Functions

  // Render
  return (
    <>
      {isLoading ? (
        <span className="center-everything">
          <LoadingDirsys />
        </span>
      ) : (
        userDetails && (
          <>
            {
              <Suspense fallback={loadingMarkup}>
                {children}
                <Routes>
                  <Route
                    path={RoutingPath.WelcomeScreen}
                    element={<WelcomeScreen />}
                  />
                  {/** Integrity */}
                  {tenantProducts.includes('integrity') && (
                    <>
                      <Route
                        element={
                          <ProtectedRoute
                            allowedRoles={
                              AccessControll.newPersonalDataProcessingViewAccess
                            }
                          />
                        }
                      >
                        <Route
                          path={RoutingPath.NewPersonalDataProcessingView}
                          element={
                            <NewPersonalDataProcessingView logout={logout} />
                          }
                        />
                      </Route>
                      <Route
                        element={
                          <ProtectedRoute
                            allowedRoles={AccessControll.indexListViewAccess}
                          />
                        }
                      >
                        <Route
                          path={RoutingPath.IndexListView}
                          element={<IndexListView logout={logout} />}
                        />
                      </Route>

                      {tenantProducts.includes('dpia') && (
                        <>
                          <Route
                            element={
                              <ProtectedRoute
                                allowedRoles={AccessControll.investigateAccess}
                              />
                            }
                          >
                            <Route
                              path={RoutingPath.InvestigateView}
                              element={<InvestigateDpia logout={logout} />}
                            />
                          </Route>
                          <Route
                            element={
                              <ProtectedRoute
                                allowedRoles={AccessControll.dpiaAccess}
                              />
                            }
                          >
                            <Route
                              path={RoutingPath.DpiaView}
                              element={<Dpia logout={logout} />}
                            />
                          </Route>
                        </>
                      )}
                    </>
                  )}

                  {/** Admin */}
                  <>
                    <Route
                      element={
                        <ProtectedRoute
                          allowedRoles={AccessControll.appAdminAccess}
                        />
                      }
                    >
                      <Route
                        path={RoutingPath.AppAdmin}
                        element={<WrapperAdmin logout={logout} />}
                      />
                    </Route>
                    <Route
                      element={
                        <ProtectedRoute
                          allowedRoles={AccessControll.userAdminAccess}
                        />
                      }
                    >
                      <Route
                        path={RoutingPath.UserAdmin}
                        element={<WrapperUserAdmin logout={logout} />}
                      />
                    </Route>
                  </>

                  {/** Security */}
                  {tenantProducts.includes('security') && (
                    <>
                      <Route
                        element={
                          <ProtectedRoute
                            allowedRoles={AccessControll.icPageViewAccess}
                          />
                        }
                      >
                        <Route
                          path={RoutingPath.IcPageView}
                          element={<IcPageView logout={logout} />}
                        />
                      </Route>
                      <Route
                        element={
                          <ProtectedRoute
                            allowedRoles={AccessControll.icPageViewAccess}
                          />
                        }
                      >
                        <Route
                          path={
                            RoutingPath.DetailedInformationCarrierView +
                            '/:icId'
                          }
                          element={
                            <WrapperDetailedInformationCarrier
                              logout={logout}
                            />
                          }
                        />
                      </Route>
                    </>
                  )}

                  {/** Security Framework*/}
                  {tenantProducts.includes('framework') && (
                    <Route
                      element={
                        <ProtectedRoute
                          allowedRoles={AccessControll.securityFramework}
                        />
                      }
                    >
                      <Route
                        path={RoutingPath.SecurityFramework}
                        element={<SecurityFramework logout={logout} />}
                      />
                    </Route>
                  )}
                  {/** Insights */}
                  {tenantProducts.includes('insights') && (
                    <Route
                      element={
                        <ProtectedRoute
                          allowedRoles={AccessControll.insights}
                        />
                      }
                    >
                      <Route
                        path={RoutingPath.Insights}
                        element={<DashboardView logout={logout} />}
                      />
                    </Route>
                  )}

                  {/** Fallback routes */}

                  <Route
                    path={RoutingPath.ResetPassword}
                    element={<ResetPassword />}
                  />
                  <Route path={RoutingPath.Forbidden} element={<Forbidden />} />
                  <Route
                    element={<ServerIsDownView />}
                    path={RoutingPath.ServerIsDown}
                  />
                  <Route path={RoutingPath.Error} element={<ErrorView />} />
                </Routes>
              </Suspense>
            }
          </>
        )
      )}
    </>
  );
};
