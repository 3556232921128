import { http } from "../backendUserAdmin";
const personalDataProcess = 'personal-data-process';

// get stats for dash ///

const getStatisticsIntegrity = () => {
	
	return http.get(`${personalDataProcess}/statistics/integrity`);
};

const getStatisticsSecurity = () => {
	return http.get(`${personalDataProcess}/statistics/security`);
};

const getStatisticsReviewCounter = () => {
	return http.get(`${personalDataProcess}/statistics/integrity/counter`);
};


export default {
    getStatisticsIntegrity,
	getStatisticsSecurity,
    getStatisticsReviewCounter
};
