import { riskMeasure } from '../../interface/Interface';
import { http } from '../backendUserAdmin';
const sf = 'security-framework';

// CONTROL PLAN

// get all control plans
const getAllControlPlans = () => {
  return http.get(`${sf}/get-all-control-plans`);
};

// get all control plans
const getAllControlPlansById = (icId: number) => {
  return http.get(`${sf}/get-all-control-plans/${icId}`);
};

// get control plan by id
const getAControlPlanById = (id: number) => {
  return http.get(`${sf}/get-control-plan-by-id/${id}`);
};

//get control plan by id and sort
const getControlPlanByIdSort = (id: number, columnId: number, dir: string) => {
  // ex, 1,1,asc
  return http.get(
    `${sf}/get-control-plan-by-id/${id}/sort/${columnId}/by/${dir}`
  );
};

// create control plan
const postControlPlan = (plan: any) => {
  return http.post(`${sf}/create-control-plan`, plan);
};

// update control plan
const putControlPlan = (plan: any) => {
  return http.put(`${sf}/update-control-plan`, plan);
};

// delete control plan
const deleteControlPlan = (id: number) => {
  return http.delete(`${sf}/delete-control-plan/${id}`);
};

//CONTROL POINT

//get control point by id
const getControlPointById = (id: number) => {
  return http.get(`${sf}/get-control-point-by-id/${id}`);
};

//create control point
const postControlPoint = (controlPlanId: number, point: any) => {
  return http.post(`${sf}/${controlPlanId}/create-control-point`, point);
};

//put control point
const putControlPoint = (controlPointId: number, point: any) => {
  return http.put(`${sf}/update-control-point/${controlPointId}`, point);
};

//delete control point by id
const deleteControlPoint = (controlPointId: number) => {
  return http.delete(`${sf}/delete-control-point/${controlPointId}`);
};

// Generated GAP

//get  generated GAP by id
const getGenerated = (icId: number) => {
  return http.get(`${sf}/get-generated-requirements/${icId}`);
};

//get  generated GAP sorted
const getGeneratedAndSorted = (
  icId: number,
  controlPlanId: number,
  headerId: number,
  order: string
) => {
  return http.get(
    `${sf}/get-generated-requirements/${icId}/${controlPlanId}/sort/${headerId}/by/${order}`
  );
};

//get analysis measures by id
const getAnalysisMeasures = (icId: number, page: number, fetchAll: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 5;

  return http.get(
    `${sf}/get-analysis-measures/${icId}/slice?page=${page}&size=${elementSize}`
  );
};

const getAnalysisMeasuresSorted = (
  icId: number,
  headerId: string,
  order: string,
  page: number,
  fetchAll: any
) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 5;
  return http.get(
    `${sf}/get-analysis-measures/${icId}/sort/${headerId}/by/${order}/slice?page=${page}&size=${elementSize}`
  );
};

//post analysis measures by id
const postAnalysisMeasures = (
  controlPointId: number,
  icId: number,
  measure: riskMeasure
) => {
  return http.post(
    `${sf}/${controlPointId}/create-analysis-measure/${icId}`,
    measure
  );
};

//put analysis measures by id
const putAnalysisMeasures = (measureId: number, measure: riskMeasure) => {
  return http.put(`${sf}/update-analysis-measure/${measureId}`, measure);
};

//put fulfill value
const putFulfill = (controlPointId: number, icId: number, value: any) => {
  return http.put(
    `${sf}/${controlPointId}/update-fulfill-value/${icId}/${value}`
  ); // green-red
};

//put comment value
const putComment = (controlPointId: number, icId: number, data: any) => {
  return http.put(
    `${sf}/${controlPointId}/update-control-point-comment/${icId}?comment=${data}`
  ); // green-red
};

//update generated plan
const putGeneratedPlan = (id: number, data: any[]) => {
  return http.put(`${sf}/update-generated-plans/${id}`, data);
};

//delete Measure by id
const deleteAnalysisMeasure = (measureId: number) => {
  return http.delete(`${sf}/delete-analysis-measure/${measureId}`);
};

const disconnectGeneratedPlan = (icId: number, id: number) => {
  return http.put(`${sf}/disconnect-generated-plan/${icId}/${id}`);
};

export default {
  getAllControlPlans,
  getAControlPlanById,
  getControlPlanByIdSort,
  postControlPlan,
  putControlPlan,
  getControlPointById,
  postControlPoint,
  putControlPoint,
  deleteControlPoint,
  deleteControlPlan,
  deleteAnalysisMeasure,
  disconnectGeneratedPlan,
  putAnalysisMeasures,
  postAnalysisMeasures,
  getAnalysisMeasures,
  getGenerated,
  getAnalysisMeasuresSorted,
  putGeneratedPlan,
  getGeneratedAndSorted,
  getAllControlPlansById,
  putFulfill,
  putComment,
};
