// Import of packages
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import components, context, ect
import RoutingPath from '../../routes/RoutingPath';
import { Button } from '../button/Button';
import GateKeeper from '../../shared/Permissions/GateKeeper';
import MUITooltip from '../MUI/Tooltip/MUITooltip';

// Import of views

// Import of context, functions, hook ect...
import { ActiveModeContext } from '../../shared/provider/ActiceModeProvider';
import useOnClickOutside from '../../shared/hooks/useOnClickOutside';
import AccessControll from '../../shared/Permissions/AccessControll';
import { DataContext } from '../../shared/provider/DataProvider';

// Import of images
import settings from '../../shared/images/settings.png';
import compas from '../../shared/images/compasW.png';

import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
//css

import { TenantContext } from '../../shared/provider/TenantProvider';

// Imoprt of CSS, SASS
import './navigation.css';

export const SidebarNavigation = () => {
  // Destruct props

  // State and hooks
  const [inActiveMode, setInActiveMode] = useContext<any>(ActiveModeContext);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [route, setRoute] = useState('');
  const { userInformationCarrierTitle } = useContext(DataContext);
  const { tenantProducts } = useContext<any>(TenantContext);

  const ref = useRef(null);
  const handleClickOutside = () => setShowAdminMenu(false);

  useOnClickOutside(ref, handleClickOutside);
  const navigate = useNavigate();
  // lng
  const { t } = useTranslation();

  let location = useLocation();

  // rest route
  useEffect(() => {
    setRoute('');
  }, [location.pathname, setInActiveMode]);

  // berfore render
  useEffect(() => {
    if (location.pathname === RoutingPath.NewPersonalDataProcessingView) {
      setInActiveMode(true);
    } else setInActiveMode(false);
  }, [location.pathname, setInActiveMode]);

  // Variables
  const toolTipContentIndex = `${t(
    'components.sidebarNavigation.registers',
    'Registerförteckning'
  )}`;
  const toolTipContentInvestigate = `${t(
    'components.sidebarNavigation.investigate',
    'Utred behov av konsekvensbedömning'
  )}`;
  const toolTipContentDpia = `${t(
    'components.sidebarNavigation.dpia',
    'Konsekvensbedömning'
  )}`;

  const iconMuiStyle = {
    color: '#fff',
    margin: '0',
  };

  const sideBar = () => {
    const styleShield = {
      left: '130%',
      width: 'auto',
      top: '10px',
    };
    const style = {
      left: '130%',
      width: 'auto',
      minWidth: '100px',
    };
    const styleFramework = {
      left: '130%',
      width: 'auto',
    };

    // Functions
    // handle routing with clickhandler
    const clickHandler = (route: string, id?: string) => {
      navigate(route);
    };

    // Render
    const gearIcon = () => {
      // settings menu
      return (
        <GateKeeper actions={AccessControll.settings}>
          <span className="admin-icon" data-cy="admin-testId">
            <img
              src={settings}
              alt="settings-icon"
              id="rotete-settings-icon"
              onClick={() => setShowAdminMenu(true)}
            />

            {showAdminMenu && (
              <div className={`hover-setting-menu ${''}`} ref={ref}>
                <>
                  <GateKeeper actions={AccessControll.userAdminAccess}>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={RoutingPath.UserAdmin}
                        style={{
                          textDecoration: 'none',
                          color: '#6D6969',
                        }}
                      >
                        <Button
                          buttonText={t(
                            'components.sidebarNavigation.users',
                            'Användare'
                          )}
                          onClick={(e: any) => {
                            handleImageClick(
                              e,
                              RoutingPath.UserAdmin,
                              'UserAdmin'
                            );
                            setShowAdminMenu(false);
                          }}
                        />
                      </a>
                    </div>
                  </GateKeeper>
                </>
                <>
                  <GateKeeper actions={AccessControll.appAdminAccess}>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={RoutingPath.AppAdmin}
                        style={{
                          textDecoration: 'none',
                          color: '#6D6969',
                        }}
                      >
                        <Button
                          buttonText={t(
                            'components.sidebarNavigation.admin',
                            'Administration'
                          )}
                          /*  onClick={() => {
                        clickHandler(RoutingPath.AppAdmin, 'AppAdmin');
                        setShowAdminMenu(false);
                      }} */
                          onClick={(e: any) => {
                            handleImageClick(
                              e,
                              RoutingPath.AppAdmin,
                              'AppAdmin'
                            );
                            setShowAdminMenu(false);
                          }}
                        />
                      </a>
                    </div>
                  </GateKeeper>
                </>
              </div>
            )}
          </span>
        </GateKeeper>
      );
    };

    const handleImageClick = (
      e: { preventDefault: () => void },
      route: string,
      id: string
    ) => {
      e.preventDefault();
      navigate(route);
    };
    return (
      <div className="navigation-ul-li">
        <ul className="navigation-side-menu">
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={RoutingPath.WelcomeScreen}
            >
              <li className="dirsys-logga bg-loga-dirsys">
                <img
                  id="logga"
                  src={compas}
                  //src={compasXmas}
                  alt="icon"
                  /*               onClick={() => clickHandler(RoutingPath.DashboardView)}
                   */ onClick={(e) =>
                    handleImageClick(e, RoutingPath.WelcomeScreen, '')
                  }
                />
              </li>
            </a>
          </div>
          {tenantProducts.includes('integrity') && (
            <>
              <GateKeeper actions={['PERSONALDATAPROCESS_READ']}>
                <MUITooltip
                  content={toolTipContentIndex}
                  linkTo={RoutingPath.IndexListView}
                  navigateClick={(e: React.SyntheticEvent) =>
                    handleImageClick(e, RoutingPath.IndexListView, 'index')
                  }
                  muiIcon={<FeedOutlinedIcon style={iconMuiStyle} />}
                />
              </GateKeeper>

              {tenantProducts.includes('dpia') && (
                <>
                  <GateKeeper actions={['INVESTIGATION_READ']}>
                    <MUITooltip
                      content={toolTipContentInvestigate}
                      linkTo={RoutingPath.InvestigateView}
                      navigateClick={(e: React.SyntheticEvent) =>
                        handleImageClick(
                          e,
                          RoutingPath.InvestigateView,
                          'investigate'
                        )
                      }
                      // images={investigate}
                      muiIcon={<PolicyOutlinedIcon />}
                    />
                  </GateKeeper>
                  <GateKeeper actions={['DPIA_READ']}>
                    <MUITooltip
                      content={toolTipContentDpia}
                      linkTo={RoutingPath.DpiaView}
                      navigateClick={(e: React.SyntheticEvent) =>
                        handleImageClick(e, RoutingPath.DpiaView, 'dpia')
                      }
                      muiIcon={<GppMaybeOutlinedIcon style={iconMuiStyle} />}
                    />
                  </GateKeeper>
                </>
              )}
            </>
          )}
          {tenantProducts.includes('security') && (
            <GateKeeper actions={['SECURITY_READ']}>
              <MUITooltip
                content={
                  userInformationCarrierTitle
                    ? userInformationCarrierTitle.value
                    : t(
                        'components.sidebarNavigation.informationCarrier',
                        'Informationsobjekt'
                      )
                }
                linkTo={RoutingPath.IcPageView}
                navigateClick={(e: React.SyntheticEvent) =>
                  handleImageClick(e, RoutingPath.IcPageView, 'security')
                }
                // images={padlock}
                muiIcon={<LockOutlinedIcon style={iconMuiStyle} />}
              />
            </GateKeeper>
          )}
          {tenantProducts.includes('framework') && (
            <GateKeeper actions={['FRAMEWORK_READ']}>
              <MUITooltip
                content={t('components.sidebarNavigation.framework', 'Ramverk')}
                linkTo={RoutingPath.SecurityFramework}
                navigateClick={(e: React.SyntheticEvent) =>
                  handleImageClick(
                    e,
                    RoutingPath.SecurityFramework,
                    'securityFramework'
                  )
                }
                // images={framwork}
                muiIcon={<InventoryOutlinedIcon style={iconMuiStyle} />}
              />
            </GateKeeper>
          )}
          {tenantProducts.includes('insights') && (
            <GateKeeper actions={['DASHBOARD_READ']}>
              <MUITooltip
                content={t('components.sidbarNavigation.insights', 'Insikter')}
                linkTo={RoutingPath.Insights}
                navigateClick={(e: React.SyntheticEvent) =>
                  handleImageClick(e, RoutingPath.Insights, 'dashboard')
                }
                // images={dashboard}
                muiIcon={<BarChartOutlinedIcon style={iconMuiStyle} />}
              />
            </GateKeeper>
          )}
          <GateKeeper actions={['APPLICATION_ADMINISTRATOR']}>
            {gearIcon()}
          </GateKeeper>
        </ul>
      </div>
    );
  };

  return (
    <div className="navigation-ul-li" style={{ height: '0px' }}>
      {sideBar()}
    </div>
  );
};
