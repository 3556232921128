import { findCommonElement } from "../function/function";

const hasAccess = (userPermission: any, actions: string[]) => {
  let found = false;

    for (const group of userPermission) {
        found = actions.some((action) => {
          return group.permissions.includes(action); // Return the result of includes()
        });
      
        if (found) {
          return true; // Return true if any match is found
        }
      }
      
      return false; // Return false if no match is found in any group
}




const grantPermission = (inputActions: any[], actions: any[]) => {
 return findCommonElement(inputActions,actions )
}

export { hasAccess,grantPermission };
