import { userPassword } from 'src/shared/interface/Interface';
import { http } from '../backendUserAdmin';

const personalDataProcess = 'personal-data-process';

// locale
const putLocale = (userId: string | number, locale: string) => {
  return http.put(`users/${userId}/locale?lang=${locale}`);
};

/// user ///

// new endpoint to get users 
const getUserNewEndpoint = (params?: string) => {  
  return http.get(`users${ params && params}`);
};

const getUsers = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(`users?page=${page}&size=${elementSize}`);
};

const getAllUsers = () => {
  return http.get(`users/responsible`);
};

const getAllUsersWithoutPagination = () => {
  return http.get('users');
};

const addUser = (user: any) => {
  return http.post('users', user);
};

const updateUserById = (user: any) => {  
  let userId = user.dbId;
  return http.put(`users/${userId}`, user);
};

const deleteUserById = (user: any) => {
  let userId = user.dbId;

  return http.delete(`users/${userId}`);
};

const userDetails = (userId: string) => {
  return http.get(`users/${userId}`);
};


//search for user
const searchForUser = (page: number, search: string, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  
  return http.get(`users?page=${page}&size=${elementSize}&search=${search}`);
};

// roles

const getRoles = (query?:string) => {
  return http.get(`roles?${query}`)
}
const getAllRoles = () => {  
  return http.get(`roles?showAll=true`);
};

const getAppRoles = () => {  
  return http.get(`roles`);
};

const getAdministrationRoles = () => {
  return http.get(`roles`);
};

const postRoles = (dto: any) => {
  return http.post('roles', dto);
};

const putRoles = (dto: any) => {  
  return http.put(`roles/${dto.id}`, dto);
};

const deleteRole = (id: number) => {
  return http.delete(`roles/${id}`);
};

/* const assignRoleToUser = (userId: string, rolesList: any) => {
  return http.post(`user/${userId}/roles`, rolesList);
};

const associationToRole = (userId: string, association: any) => {
  return http.put(`user/${userId}/association`, association);
}; */

//  Controller

const getAllController = () => {  
  return http.get(`${personalDataProcess}/controllers`);
};

const getControllerPagination = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `${personalDataProcess}/controllers/slice?page=${page}&size=${elementSize}`
  );
};
const addController = (newController: any) => {
  return http.post(`${personalDataProcess}/controllers`, newController);
};

const updateController = (updatedController: any) => {
  return http.put(
    `${personalDataProcess}/controllers/${updatedController.id}`,
    updatedController
  );
};

const deleteController = (id: number) => {
  return http.delete(`${personalDataProcess}/controllers/${id}`);
};

//current logged in users controller
const getCurrentUsersControllers = () => {
  return http.get(`${personalDataProcess}/user/controllers`);
};
//current logged in users controller
const getCurrentUsersControllersWithCreate = () => {
  return http.get(`${personalDataProcess}/user/controllers?filterByPermission=CREATE`);
};

//  InformationCarriers

const getInformationCarriers = () => {
  return http.get(`${personalDataProcess}/information-carriers`);
};

const getInformationCarriersSlice = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `${personalDataProcess}/information-carriers/slice?page=${page}&size=${elementSize}`
  );
};


const getAllInformationCarriers = () => {  
    return http.get(`${personalDataProcess}/information-carriers`);
};

//unfiltered by security spaces
const getAllInformationCarriersSlice = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `${personalDataProcess}/all-information-carriers/slice?page=${page}&size=${elementSize}`
  );
};

const addInformationCarriers = (object: { name: string }) => {
  return http.post(`${personalDataProcess}/information-carriers`, object);
};

const addInformationCarriersSecurity = (object: { name: string }) => {  
  return http.post(`${personalDataProcess}/security/information-carriers`, object);
};

const updateInformationCarriers = (updatedIc: any) => {
  const id = updatedIc.id;
  return http.put(
    `${personalDataProcess}/information-carriers/${id}`,
    updatedIc
  );
};

const updateInformationCarriersSecurity = (updatedIc: any) => {
  const id = updatedIc.id;
  return http.put(
    `${personalDataProcess}/security/${id}`,
    updatedIc
  );
};

const deleteInformationCarrier = (id: number) => {
  return http.delete(`${personalDataProcess}/information-carriers/${id}`);
};

const deleteInformationCarrierSecurity = (id: number) => {
  return http.delete(`${personalDataProcess}/security/${id}`);
};

// reviwed by

const postReviewed = (reviwedId: any) => {
  return http.patch(`${personalDataProcess}/${reviwedId}/review`);
};

const deleteReviewed = (reviwedId: any) => {
  return http.delete(`${personalDataProcess}/${reviwedId}/review`);

  //return http.post("personal-data/reviewed", reviwedId);
};

// time range

const getTimeRange = () => {
  return http.get(`${personalDataProcess}/customise/all`);
};

const putTimeRange = (id: string) => {
  return http.put(`${personalDataProcess}/customise/${id}`);
};

// custom titles

const getCustomTitles = () => {
  return http.get(`${personalDataProcess}/customise/get/all`);
};

const putCustomTitle = (data: any) => {
  return http.put(`${personalDataProcess}/customise/update`, data);
};

// groups

//search for group
const searchForGroup = (page: number, search: string, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(`groups?page=${page}&size=${elementSize}&search=${search}`);
};

const getAllGroups = () => {  
  return http.get(`/groups`);
};

const getGroupsByPermission = (params: string) => {  
  return http.get(`/groups${params}`);
};

const getGroups = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `/groups?page=${page}&size=${elementSize}`
  );
};

const addGroup = (group: any) => {
  return http.post(`/groups`, group);
};
const putGroup = (group: any) => {
  return http.put(`/groups/${group.id}`, group);
};

const deleteGroup = (group: any) => {
  return http.delete(`/groups/${group.id}`);
};

// update password

const getPasswordPolicy = () => {
  return http.get(`access-management/password-policy`);
};

const putPasswordPolicy = (data: any) => {
  return http.put(`access-management/password-policy`, data);
};

// update USER password

const putUserPassword = (data: userPassword) => {
  return http.put(`/access-management/update-password`, data);
};

// update otp

const getOtp = () => {
  return http.get(`access-management/otp`);
};

const putOtp = (data: boolean) => {
  return http.put(`access-management/otp`, data);
};

// update SSO

const getSSOSettings = () => {
  return http.get(`${'keycloak'}/sso`);
};

const postSSOSettings = (data: any) => {
  return http.post(`${'keycloak'}/sso`, data);
};

const putSSOSettings = (data: any) => {
  return http.put(`${'keycloak'}/sso/${data.alias}`, data);
};

// messages
const getMessage = () => {
  return http.get(`notifications`);
};

const deleteMessage = (id: number) => {
  return http.delete(`notifications/${id}`);
};

// Uniq Name Requirement
const getUniqNameRequirement = () => {
  return http.get(`${personalDataProcess}/settings`);
};

const putUniqNameRequirement = (data: any) => {
  return http.put(`${personalDataProcess}/settings`, data);
};

export default {
  getUniqNameRequirement,
  putUniqNameRequirement,
  getUsers,
  addUser,
  deleteUserById,
  updateUserById,
  searchForUser,
  // searchForRole,
  getRoles,
  postRoles,
  getAllController,
  getControllerPagination,
  addController,
  deleteController,
  getCurrentUsersControllers,
  postReviewed,
  deleteReviewed,
  getInformationCarriers,
  addInformationCarriers,
  deleteInformationCarrier,
  deleteInformationCarrierSecurity,
  updateController,
  updateInformationCarriers,
  updateInformationCarriersSecurity,
  getTimeRange,
  putTimeRange,
  getCustomTitles,
  putCustomTitle,
  getGroups,
  getGroupsByPermission,
  addGroup,
  putGroup,
  searchForGroup,
  deleteGroup,
  getPasswordPolicy,
  putPasswordPolicy,
  getSSOSettings,
  putSSOSettings,
  putRoles,
  deleteRole,
  userDetails,
  getOtp,
  putOtp,
  getInformationCarriersSlice,
  getAllInformationCarriersSlice,
  getAllGroups,
  getAppRoles,
  getAllUsers,
  getAllRoles,
  postSSOSettings,
  getAllUsersWithoutPagination,
  getAdministrationRoles,
  getAllInformationCarriers,
  addInformationCarriersSecurity,
  getMessage,
  deleteMessage,
  putLocale,
  getCurrentUsersControllersWithCreate,
  getUserNewEndpoint,
  putUserPassword
};
