import { http, httpStratsys } from '../backendUserAdmin';

/// get ///
const getToken = async (): Promise<any> => {
  const result = await http.get('stratsys/token');
  //console.log(JSON.parse(result.data.result));
  return result;
};

//https://www.stratsys.se/api/v1/scorecards/8/departments/6/nodes/
//https://www.stratsys.se/api/v1/scorecards/8/departments/6/nodes/195031/descriptionfields - answers
// https://www.stratsys.se/api/v1/keywordgroups?scorecardColumnId=81 - ja och nej frågor

const getSettings = async (): Promise<any> => {
  const result = http.get('stratsys/settings');
  return (await result).data.result;
};

const getTenant = async (): Promise<any> => {
  const result = http.get('stratsys/settings');
  return (await result).data.result.tenant;
};

const getInfoSekScorecardName = async (): Promise<any> => {
  const result = http.get('stratsys/settings');
  return (await result).data.result.infoSekCardName;
};

const getScorecardColumnName = async (): Promise<any> => {
  const result = http.get('stratsys/settings');
  return (await result).data.result.scorecardColumnName;
};

const getMappings = async (): Promise<any> => {
  const result = http.get('stratsys/mappings');
  return (await result).data.result;
};

const postMappings = async (mappings: any): Promise<any> => {
  const result = await http.post('stratsys/mappings', mappings);
  return result;
};
const deleteMappings = async (): Promise<any> => {
  const result = http.delete('stratsys/mappings');
  return (await result).data.result;
};

const getAllControllers = async () => {
  const result = http.get('personal-data-process/controllers');
  return (await result).data.result;
};

const getAllDescriptionFields = async (scorecardColumnId: number) => {
  const result = await httpStratsys.get(
    'scorecardcolumns/' + scorecardColumnId + '/descriptionfields'
  );
  return result.data.Result;
};

const getScorecardColumn = async (scorecardId: number) => {
  const result = await httpStratsys.get(
    'scorecards/' + scorecardId + '/columns'
  );
  return result.data.Result;
};

const getAllDescriptionFieldValues = async () => {
  const result = await httpStratsys.get('descriptionfields');
  return result.data.Result;
};

const getAllDepartments = async () => {
  const result = await httpStratsys.get('departments');
  return result.data.Result;
};

const getAllScorecards = async () => {
  const result = await httpStratsys.get('scorecards');
  return result.data.Result;
};

const getAllScorecardColumns = async () => {
  const result = await httpStratsys.get('scorecardcolumns/filter');
  return result.data.Result;
};

const getAllScorecardNodes = async (
  scorecardId: number,
  departmentId: number
) => {
  const result = await httpStratsys.get(
    'scorecards/' + scorecardId + '/departments/' + departmentId + '/nodes'
  );
  return result.data.Result;
};

/// post ///
const postScorecardNode = async (
  scorecardId: string,
  departmentId: number,
  scorecardObj: any
) => {
  const result = await httpStratsys.post(
    'scorecards/' + scorecardId + '/departments/' + departmentId + '/nodes',
    scorecardObj
  );
  return result.data.Result;
};

const submitData = async (
  controllerForm: any,
  answerForm: any,
  personalDataProcesses: any,
  activeScoreCardId: any,
  activeScoreCardColumnId: any
) => {
  try {
    const response = await http.post('stratsys/submit-data', {
      controllerForm,
      answerForm,
      personalDataProcesses,
      activeScoreCardId,
      activeScoreCardColumnId,
    });
  } catch (error) {
    console.error('Error during POST request:', error);
  }
};

const putDescriptionFieldValue = async (
  scorecardId: string,
  departmentId: number,
  nodeId: number,
  descriptionFieldObj: any
) => {
  const result = await httpStratsys.put(
    'scorecards/' +
      scorecardId +
      '/departments/' +
      departmentId +
      '/nodes/' +
      nodeId +
      '/descriptionfields',
    descriptionFieldObj
  );

  return result.data.Result;
};

export default {
  getToken,
  getSettings,
  getAllDescriptionFields,
  getScorecardColumn,
  getTenant,
  getMappings,
  postMappings,
  submitData,
  deleteMappings,
  getInfoSekScorecardName,
  getScorecardColumnName,
  getAllControllers,
  getAllDepartments,
  getAllScorecards,
  getAllScorecardColumns,
  getAllScorecardNodes,
  postScorecardNode,
  putDescriptionFieldValue,
};
