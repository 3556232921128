import React, { useState, createContext, useEffect } from 'react';
import informationCarrierService from '../api/services/informationCarrierService';
import userAdminService from '../api/services/userAdminService';
import { serverError } from '../function/function';

export const UserContext = createContext<any>(null);

export const UserProvider = (props: { children?: React.ReactChild }) => {
  const { children } = props;

  const [users, setUsers] = useState<any>([]);
  const [appRoles, setAppRoles]: any = useState([]);
  const [allController, setAllController] = useState<any>([]);
  const [currentUserController, setCurrentUserController] = useState<any>([]);
  const [currentUserControllerWithCreate, setCurrentUserControllerWiteCreate] =
    useState<any>([]);
  const [allInformationCarriers, setAllInformationCarriers] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  // current user state, devided up to eaiser controll access across application
  const [allGroups, setAllGroups] = useState<any>([]);
  const [waitForController, setWaitForController] = useState<any>(false);
  const [allSecuritySpaces, setAllSecuritySpaces] = useState<any>([]);
  const [currentSecuritySpaces, setCurrentSecuritySpaces] = useState<any>([]);
  const [currentSecuritySpacesWithCreate, setCurrentSecuritySpacesWithCreate] =
    useState<any>([]);
  const [pageNumberForUser, setPageNumberForUser] = useState<number>(1);
  const [userWithoutPagination, setUserWithoutPagination] = useState<any>([]);
  const [informationCarrierForIntegrity, setInformationCarrierForIntegrity] =
    useState<any>([]);
  const [passwordPolicyFromDB, setPasswordPolicyFromDB] = useState<any>();

  useEffect(() => {
    setWaitForController(true);
    fetchController();
  }, []);

  useEffect(() => {
    fetchAllUsers();
    getUsers('?showAll=true');
    fetchInformationCarriers();
    getCurrentUsersControllers();
    fetchACurrentSecuritySpaces();
    fetchCurrentSecuritySpacesWithCreate();
    getCurrentUsersControllersWithCreate();
    fetchAllSecuritySpaces();
    fetchCurrentInformationCarriers();
    getPasswordPolicy();
  }, []);

  useEffect(() => {
    fetchUsers(pageNumberForUser);
  }, [pageNumberForUser]);

  const getPasswordPolicy = async () => {
    try {
      const data = await userAdminService.getPasswordPolicy();
      setPasswordPolicyFromDB(data?.data);
    } catch (error: any) {
      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchCurrentInformationCarriers = async (params?: any) => {
    try {
      const data =
        await informationCarrierService.getCurrentInformationCarriers(
          params ? params : ''
        );
      setInformationCarrierForIntegrity(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchAllUsers = async () => {
    try {
      const data = await userAdminService.getAllUsers();
      setUserWithoutPagination(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };
  ////////////////

  const [userWithParams, setUserWithParams] = useState([]);
  // new endpoint to replace existing when tested
  const getUsers = async (params?: string) => {
    try {
      const data = await userAdminService.getUserNewEndpoint(params && params);
      setUserWithParams(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  /////////

  // fetch all for options in dropdown, without pagination
  const fetchAllSecuritySpaces = async () => {
    try {
      const result = await informationCarrierService.getAllSecuritySpaces();

      setAllSecuritySpaces(result?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  // fetch security spaces for current user
  const fetchACurrentSecuritySpaces = async () => {
    try {
      const result = await informationCarrierService.getCurrentSecuritySpaces();

      setCurrentSecuritySpaces(result?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  // fetch security spaces for current user
  const fetchCurrentSecuritySpacesWithCreate = async () => {
    try {
      const result =
        await informationCarrierService.getCurrentSecuritySpacesWithCreate();

      setCurrentSecuritySpacesWithCreate(result?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchAppRole = async () => {
    try {
      const data = await userAdminService.getAppRoles();
      setAppRoles(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        //serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchController = async () => {
    try {
      const data = await userAdminService.getAllController();
      setAllController(data?.data.result);
      setWaitForController(false);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const getCurrentUsersControllers = async () => {
    try {
      const data = await userAdminService.getCurrentUsersControllers();
      setCurrentUserController(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const getCurrentUsersControllersWithCreate = async () => {
    try {
      const data =
        await userAdminService.getCurrentUsersControllersWithCreate();
      setCurrentUserControllerWiteCreate(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchInformationCarriers = async () => {
    try {
      const data = await userAdminService.getAllInformationCarriers();
      setAllInformationCarriers(data.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchUsers = async (pageNumber?: any, fetchAll?: any) => {
    try {
      const data = await userAdminService.getUsers(pageNumber - 1, fetchAll);
      setUsers(data);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchAllGroups = async () => {
    try {
      const data = await userAdminService.getAllGroups();
      setAllGroups(data?.data.result.content);
    } catch (error: any) {
      console.log(error, error.response);

      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  {
    /** Dashboard / inSight */
  }

  return (
    <UserContext.Provider
      value={{
        users: users,
        setUsers: setUsers,
        appRoles: appRoles,
        fetchAppRole: fetchAppRole,
        allController: allController,
        setAllController: setAllController,
        fetchUsers: fetchUsers,
        fetchController: fetchController,
        allInformationCarriers: allInformationCarriers,
        fetchInformationCarriers: fetchInformationCarriers,
        error: error,
        setError: setError,
        currentUserController: currentUserController,
        getCurrentUsersControllers: getCurrentUsersControllers,
        waitForController,
        userWithoutPagination: userWithoutPagination,
        fetchAllSecuritySpaces: fetchAllSecuritySpaces,
        allSecuritySpaces: allSecuritySpaces,
        allGroups: allGroups,
        fetchACurrentSecuritySpaces: fetchACurrentSecuritySpaces,
        currentSecuritySpaces: currentSecuritySpaces,
        fetchAllGroups: fetchAllGroups,
        fetchCurrentInformationCarriers: fetchCurrentInformationCarriers,
        informationCarrierForIntegrity: informationCarrierForIntegrity,
        fetchAllUsers: fetchAllUsers,
        getCurrentUsersControllersWithCreate:
          getCurrentUsersControllersWithCreate,
        currentUserControllerWithCreate: currentUserControllerWithCreate,
        currentSecuritySpacesWithCreate: currentSecuritySpacesWithCreate,
        fetchCurrentSecuritySpacesWithCreate:
          fetchCurrentSecuritySpacesWithCreate,
        getUsers: getUsers,
        userWithParams: userWithParams,
        getPasswordPolicy: getPasswordPolicy,
        passwordPolicyFromDB: passwordPolicyFromDB,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
