import { http } from '../backendUserAdmin';
const personalDataProcess = 'personal-data-process';

// questions //

const getQuestions = (context: string) => {
  return http.get(`${personalDataProcess}/questions/context/${context}`);
};

const postQuestions = (newQuestion: any, context: string) => {
  return http.post(
    `${personalDataProcess}/questions/context/${context}`,
    newQuestion
  );
};

const putQuestions = (updatedQuestion: any, context: string) => {
  const id = updatedQuestion.id;
  return http.put(
    `${personalDataProcess}/questions/${id}/context/${context}`,
    updatedQuestion
  );
};

// update option
const putOption = (option: any, questionId: string) => {  
  const optionId = option.id;
  return http.put(
    `${personalDataProcess}/questions/${questionId}/options/${optionId}?name=${option.name}`
  );
};

const deleteQuestion = (q: any) => {
  const id = q.id;
  return http.delete(`${personalDataProcess}/questions/${id}`);
};

/// personal data process ///
//?page=pageNumber&size=elementSize

// get pdp based on filter value
const getDynamicPersonalData = (base?: any, arr?: any, fields?: string) => {  
  return http.get(`${personalDataProcess}?${base.map((a: string)=>a+'='+arr.map((b: any)=>b).join(',')).join(',')}${fields}`);
};

const getAllPersonalData = () => {
  return http.get(`${personalDataProcess}`);
};

const getPersonalData = (page: number, fetchAll?: number) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `${personalDataProcess}/slice?page=${page}&size=${elementSize}`
  );
};

const getPersonalDataMasterSearch = (page: number, query: string | number, fetchAll?: number ) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `${personalDataProcess}/slice?page=${page}&size=${elementSize}&search=${query}`
  );
};

const getPersonalDataSearch = (
  searchParam: any,
  page: number,
  fetchAll?: number
) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.post(
    `${personalDataProcess}/filter?page=${page}&size=${elementSize}`,
    searchParam
  );
};

const postPersonalData = (answers: any) => {
  return http.post(personalDataProcess, answers);
};

const postPersonalDataList = (personalDataProcesses: any) => {
  return http.post(`${personalDataProcess}/multi`, personalDataProcesses);
};

const putPersonalData = (answers: any) => {
  const id = answers.id;
  return http.put(`${personalDataProcess}/${id}`, answers);
};

const deletePdp = (id: any) => {
  return http.delete(`${personalDataProcess}/${id}`);
};

// add new option to question

const addNewOptiontoQuestion = (questionId: any, newOption: string) => {
  return http.post(
    `${personalDataProcess}/questions/${questionId}/options?name=${newOption}`);
};

// get pdp for investigation ///

const getPdpForInvestigation = () => {
  return http.get(`${personalDataProcess}/investigations`);
};

const getOneInvestigation = (pdpId: number) => {
  return http.get(`${personalDataProcess}/${pdpId}/investigations`);
};

const putInvestigation = (pdpId: number, invId: number, result: any) => {
  return http.put(
    `${personalDataProcess}/${pdpId}/investigations/${invId}`,
    result
  );
};

// get dpia
const getDpia = () => {
  return http.get(`${personalDataProcess}/dpia`);
};

const getOneDpia = (pdpId: number) => {
  return http.get(`${personalDataProcess}/${pdpId}/dpia`);
};

// put dpia ex.. documentation
const putDpia = (pdpId: number, dpiaId: number, result: any) => {
  return http.put(`${personalDataProcess}/${pdpId}/dpia/${dpiaId}`, result);
};

// post risk
const postRisk = (pdpId: number, dpiaId: number, result: any) => {
  return http.post(
    `${personalDataProcess}/${pdpId}/dpia/${dpiaId}/risks`,
    result
  );
};

// put risk
const putRisk = (
  pdpId: number,
  dpiaId: number,
  riskId: number,
  result: any
) => {
  return http.put(
    `${personalDataProcess}/${pdpId}/dpia/${dpiaId}/risks/${riskId}`,
    result
  );
};

// delete risk
const deleteRisk = (pdpId: number, dpiaId: number, riskId: number) => {
  return http.delete(
    `${personalDataProcess}/${pdpId}/dpia/${dpiaId}/risks/${riskId}`
  );
};

// post measure
const postRiskMeasure = (
  pdpId: number,
  dpiaId: number,
  riskId: number,
  result: any
) => {
  return http.post(
    `${personalDataProcess}/${pdpId}/dpia/${dpiaId}/risks/${riskId}/measures`,
    result
  );
};

// put measure
const putRiskMeasure = (
  pdpId: number,
  dpiaId: number,
  riskId: number,
  measureId: any,
  result: any
) => {
  return http.put(
    `${personalDataProcess}/${pdpId}/dpia/${dpiaId}/risks/${riskId}/measures/${measureId}`,
    result
  );
};

// delete measure
const deleteRiskMeasure = (
  pdpId: number,
  dpiaId: number,
  riskId: number,
  measureId: number
) => {
  return http.delete(
    `${personalDataProcess}/${pdpId}/dpia/${dpiaId}/risks/${riskId}/measures/${measureId}`
  );
};

// for sorting of questions
const patchOrder = (context: string, sort: any) => {
  return http.patch(
    `${personalDataProcess}/questions/context/${context}/sort`,
    sort
  );
};

// Get all info pdp

const getAllInfoForExport = (id: number) => {
  return http.get(`${personalDataProcess}/${id}/export`);
};

export default {
  getQuestions,
  getAllPersonalData,
  getPersonalData,
  postPersonalData,
  postPersonalDataList,
  postQuestions,
  deleteQuestion,
  putQuestions,
  deletePdp,
  putPersonalData,
  getPersonalDataSearch,
  addNewOptiontoQuestion,
  getPdpForInvestigation,
  putInvestigation,
  getOneInvestigation,
  getDpia,
  getOneDpia,
  putDpia,
  postRisk,
  putRisk,
  postRiskMeasure,
  putRiskMeasure,
  deleteRisk,
  patchOrder,
  deleteRiskMeasure,
  getAllInfoForExport,
  putOption, 
  getPersonalDataMasterSearch,
  getDynamicPersonalData
};
