import Keycloak from 'keycloak-js';
const environment = window.location.href.includes('localhost')
  ? 'local'
  : window.location.href.includes('tenant')
  ? 'dev'
  : window.location.href.includes('-qa')
  ? 'qa'
  : window.location.href.includes('beta')
  ? 'beta'
  : 'prod';

const tenantName = window.location.href.split('.')[0].split('//')[1];

const authUrl = process.env.REACT_APP_KEYCLOAK_URL;

const config = {
  realm: environment === 'local' ? 'tenant1' : tenantName,
  url:
    environment === 'local'
      ? 'https://keycloak01.dirsys.app/'
      : environment === 'dev'
      ? 'https://keycloak01.dirsys.app/'
      : environment === 'qa'
      ? 'https://keycloak-qa.dirsys.app/'
      : environment === 'beta' || environment === '-beta'
      ? 'https://auth-beta.dirsys.app/'
      : 'https://auth.dirsys.app/auth/',
  'ssl-required': 'external',
  clientId: 'web-dirsys',
  resource: 'web-dirsys',
  'public-client': true,
  'verify-token-audience': true,
  'use-resource-role-mappings': true,
  'confidential-port': 0,
};

// look for url to determine what config to use
const _kc = new Keycloak(config);
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'login-required',

      // onLoad: 'check-sso',
      // silentCheckSsoRedirectUri:
      //   window.location.origin + '/silent-check-sso.html',
      // silentCheckSsoFallback: false,
      pkceMethod: 'S256',
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      //	if (authenticated) {
      onAuthenticatedCallback();
      //	} else {
      //		doLogin();
      //	}
    });
};
const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;
const isLoggedIn = () => !!_kc.token;
const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);
const getUserDbId = () => _kc.tokenParsed?.dbId;
const getUserGivenName = () => _kc.tokenParsed?.given_name;
const getPermissions = () => _kc.tokenParsed?.permissions;
const getControllers = () => _kc.tokenParsed?.controllers;
const getEmail = () => _kc.tokenParsed?.email;
//const getTenant = () => _kc.tokenParsed?.azp;
const getLanguages = () => _kc.tokenParsed?.locale;
const hasRole = () => _kc.tokenParsed?.realm_access.roles;
const getKeycloakId = () => _kc.tokenParsed?.sub;
const getAssociations = () => _kc.tokenParsed?.associations;
const getTenant = () => config.realm;

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUserGivenName,
  getPermissions,
  hasRole,
  getEmail,
  getTenant,
  getControllers,
  getLanguages,
  getUserDbId,
  getKeycloakId,
  getAssociations,
  authUrl, // see if prod/beta/dev
};

export default KeycloakService;
