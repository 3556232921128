import React, { useState, createContext, useEffect } from 'react';
import personalDataService from '../api/services/personalDataService';
import { serverError } from '../function/function';
import userAdminService from '../api/services/userAdminService';
import frameworkService from '../api/services/frameworkService';
import { useLocation } from 'react-router-dom';

export const DataContext = createContext<any>(null);

export const DataProvider = (props: { children?: React.ReactChild }) => {
  const { children } = props;
  const [personalData, setPersonalData] = useState([]);
  const [pdpQuestions, setPdpQuestions]: any = useState([]);
  const [dpia, setDpia] = useState<any>([]);
  const [questionsInvestigate, setQuestionsInvestigate] = useState<any>([]);
  const [questionsDocument, setQuestionsDocument] = useState<any>([]);
  const [questionsSecurity, setQuestionsSecurity] = useState<any>([]);
  const [error, setError] = useState(null);
  const [loadingCustomTitles, setLoadingCustomTitles] = useState(false);
  const [range, setRange] = useState<any>();

  const [userControllerTitle, setUserControllerTitle] = useState<any>(null);
  const [userTooltipController, setUserTooltipController] = useState<any>(null);
  const [userInformationCarrierTitle, setUserInformationCarrierTitle] =
    useState<any>(null);
  const [userTooltipInformationCarrier, setUserTooltipInformationCarrier] =
    useState<any>(null);

  const [userSecuritySpacesTitle, setUserSecuritySpacesTitle] =
    useState<any>(null);
  const [userSecuritySpacesTooltip, setUserSecuritySpacesTooltip] =
    useState<any>(null);
  const [allControlPlans, setAllControlPlans] = useState<any>([]);
  const [allControlPlansById, setAllControlPlansById] = useState<any>([]);
  const [uniqNameRequirement, setUniqNameRequirement] = useState<boolean>();

  //for dashbord replace with new endpoint??
  const [allPdp, setAllPdp] = useState([]);

  // endpoint for dynamic pdp
  const [pdpByFields, setPdpByFields] = useState([]);

  // if we need to change tab fom outside tab-component
  const [goToTab, setGoToTab] = useState<boolean>(false);
  const [deletedTab, setDeletedTab] = useState<boolean>(false);

  // search paramas for MasterSearch - to access across app
  const [masterSearch, setMasterSearch] = useState<string>('');

  useEffect(() => {
    setLoadingCustomTitles(true);
    getTitles();
    getTimeRange();
    getAllControlPlans();
    //fetchAllPersonalData(); // REMOVE!! REDO!!!
    fetchSecurityQuestions();
    fetchQuestions();
  }, []);

  const fetchAllPersonalData = async () => {
    // error to null
    setError(null);
    try {
      const data = await personalDataService.getAllPersonalData();
      setAllPdp(data.data.result);
    } catch (error: any) {
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchPdpByFields = async (fields: any, arr: any) => {
    // error to null
    setError(null);
    try {
      const data = await personalDataService.getDynamicPersonalData(
        fields,
        arr
      );
      setPdpByFields(data.data.result);
    } catch (error: any) {
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  // endpoint settings

  const getUniqNameRequirement = async () => {
    try {
      const { data } = await userAdminService.getUniqNameRequirement();
      setUniqNameRequirement(data?.result?.uniqueNameRequired);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  // Endpoints for security framework

  const getAllControlPlans = async () => {
    try {
      const { data } = await frameworkService.getAllControlPlans();
      setAllControlPlans(data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        //serverError();
      } else {
        setError(error.response);
      }
    }
  };

  // Endpoints for security framework

  const getAllControlPlansById = async (id: number) => {
    try {
      const { data } = await frameworkService.getAllControlPlansById(id);
      setAllControlPlansById(data.result);
      return data.result;
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        //serverError();
      } else {
        setError(error.response);
      }
    }
  };
  // GET time range for review
  const getTimeRange = async () => {
    try {
      const { data } = await userAdminService.getTimeRange();
      setRange(data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchQuestions = async () => {
    try {
      const data = await personalDataService.getQuestions('pdp');

      setPdpQuestions(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        //serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchInvestigateQuestions = async () => {
    try {
      const data = await personalDataService.getQuestions('investigation');
      setQuestionsInvestigate(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        //serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchDocumentQuestions = async () => {
    try {
      const data = await personalDataService.getQuestions('dpia');
      setQuestionsDocument(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const fetchSecurityQuestions = async () => {
    try {
      const data = await personalDataService.getQuestions('security');
      setQuestionsSecurity(data?.data.result);
      return data?.data;
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const getDpia = async () => {
    try {
      const data = await personalDataService.getDpia();
      setDpia(data?.data.result);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  const getTitles = async () => {
    // needs to be updated with security spaces when BE is done

    try {
      const { data } = await userAdminService.getCustomTitles();
      data.result.filter((item: any) => {
        item.title === 'userController' &&
          setUserControllerTitle({ value: item.text });
        item.title === 'userController' &&
          setUserTooltipController({ value: item.helpText });
        item.title === 'informationCarrier' &&
          setUserInformationCarrierTitle({ value: item.text });
        item.title === 'informationCarrier' &&
          setUserTooltipInformationCarrier({ value: item.helpText });
        item.title === 'securitySpace' &&
          setUserSecuritySpacesTitle({ value: item.text });
        item.title === 'securitySpace' &&
          setUserSecuritySpacesTooltip({ value: item.helpText });
      });
      setLoadingCustomTitles(false);
    } catch (error: any) {
      console.log(error, error.response);

      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
        setLoadingCustomTitles(false);
      }
    }
  };

  const deleteControlPlan = async (id: number) => {
    try {
      await frameworkService.deleteControlPlan(id);
    } catch (error: any) {
      console.log(error, error.response);
      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
    setDeletedTab(true);
    fetchSecurityQuestions();
    getAllControlPlans();
  };

  const deleteControlPoint = async (id: number) => {
    try {
      await frameworkService.deleteControlPoint(id);
    } catch (error: any) {
      console.log(error, error.response);
      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
    getAllControlPlans();
  };

  const deleteAnalysisMeasure = async (id: number) => {
    try {
      await frameworkService.deleteAnalysisMeasure(id);
    } catch (error: any) {
      console.log(error, error.response);
      // error to null
      setError(null);
      if (error.response === undefined) {
        serverError();
      } else {
        setError(error.response);
      }
    }
  };

  // remove session storage
  const location = useLocation();
  const isPersistedPath =
    location.pathname === '/information-carrier-list' ||
    /^\/detailed-information-carrier(\/\d+)?$/.test(location.pathname);
  // regex to allow /detailed-information-carrier/ any number
  // remove session storage for filer and filterObject if navigate away from security -
  // can be put in own function when integrity get updated to use the same functionality /this is uesed to save filter state when moving in to "/detailed-information-carrier" and remove it if any other path
  useEffect(() => {
    if (!isPersistedPath) {
      sessionStorage.removeItem('filter');
      sessionStorage.removeItem('filterObject');
    }
  }, [isPersistedPath]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('filter');
      sessionStorage.removeItem('filterObject');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        fetchPdpByFields: fetchPdpByFields,
        pdpByFields: pdpByFields,
        pdpQuestions: pdpQuestions,
        personalData: personalData,
        fetchQuestions: fetchQuestions,
        questionsInvestigate: questionsInvestigate,
        fetchInvestigateQuestions: fetchInvestigateQuestions,
        questionsDocument: questionsDocument,
        fetchDocumentQuestions: fetchDocumentQuestions,
        getDpia: getDpia,
        dpia: dpia,
        // for custom titles
        userControllerTitle: userControllerTitle,
        userTooltipController: userTooltipController,
        userInformationCarrierTitle: userInformationCarrierTitle,
        userTooltipInformationCarrier: userTooltipInformationCarrier,
        userSecuritySpacesTitle: userSecuritySpacesTitle,
        userSecuritySpacesTooltip: userSecuritySpacesTooltip,
        getTitles: getTitles,
        loadingCustomTitles,
        questionsSecurity,
        fetchSecurityQuestions,
        getTimeRange: getTimeRange,
        range: range,
        //sf
        getAllControlPlans: getAllControlPlans,
        allControlPlans: allControlPlans,
        setGoToTab: setGoToTab,
        goToTab: goToTab,
        deletedTab: deletedTab,
        setDeletedTab: setDeletedTab,
        deleteControlPlan: deleteControlPlan,
        deleteControlPoint: deleteControlPoint,
        getUniqNameRequirement: getUniqNameRequirement,
        uniqNameRequirement: uniqNameRequirement,
        getAllControlPlansById: getAllControlPlansById,
        allControlPlansById: allControlPlansById,
        deleteAnalysisMeasure: deleteAnalysisMeasure,
        // Dashboard
        allPdp: allPdp,
        fetchAllPersonalData: fetchAllPersonalData,
        //masterSearch
        masterSearch,
        setMasterSearch,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
