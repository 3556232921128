//import packages
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//import components
import { Button } from '../../components/button/Button';
import { SmallModuleButton } from '../../components/card/SmallModuleButton';
import FunctionalPopUp from '../../components/popUp/funcPopup/FunctionalPopUp';

// config
import { DataContext } from '../provider/DataProvider';
import { UserContext } from '../provider/UserProvider';

// Validation errors from this file need to be put inside of a popup if hide shuold be used OR?

const WrapperError = (error) => {
  let navigate = useNavigate();
  const {
    userControllerTitle,
    userSecuritySpacesTitle,
    userInformationCarrierTitle,
  } = useContext(DataContext);

  // lng
  const { t } = useTranslation();
  const { passwordPolicyFromDB, getPasswordPolicy } = useContext(UserContext);

  useEffect(() => {
    error.error.status === 500 &&
      error.error.data?.message ===
        'Error updating password: HTTP 400 Bad Request' &&
      getPasswordPolicy();
  }, []);

  const [openFuncPopup, setOpenFuncPopup] = useState(true);

  const NoSecuritySpaces = () => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.noSecuritySpace.errorWhileUpdating',
            'Fel vid uppdatering'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <div>
            <>
              <ul className="error-list">
                <li>
                  {t(
                    'validation.validateResponse.noSecuritySpace.informarionCarrierToSecuritySpace',
                    'Ett informationsObjekt måste vara kopplad till minst ett security space'
                  )}
                </li>
              </ul>
            </>
          </div>{' '}
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.noSecuritySpace.buttonBack',
                'Tillbaka'
              )}
              onClick={(e) => {
                setOpenFuncPopup(false);
              }}
            />
          </div>{' '}
        </SmallModuleButton>
      </span>
    );
  };

  const NoContent = () => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 2100,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.noContent.unauthorized',
            'Du är ej behörig'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <div>
            <>
              <h4 style={{ textAlign: 'center', margin: '10px' }}>
                {t(
                  'validation.validateResponse.noContent.title',
                  'Det beror på följande:'
                )}
              </h4>

              <ul className="error-list">
                <li>
                  {t(
                    'validation.validateResponse.noContent.missingPermissionsController',
                    'Du saknar behörighet till minst en '
                  )}
                  {userControllerTitle
                    ? ' ' + userControllerTitle.value.toLocaleLowerCase()
                    : t(
                        'validation.validateResponse.noContent.controller',
                        ' personuppgiftsansvarig'
                      )}
                </li>
              </ul>
            </>
          </div>{' '}
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.noContent.buttonToStartPage',
                'Till startsidan'
              )}
              onClick={() => navigate('/')}
            />
          </div>{' '}
        </SmallModuleButton>
      </span>
    );
  };

  const NotEmpty = () => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 2100,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.notEmpty.removeConnection',
            'Ta bort koppling'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <div>
            <>
              <ul className="error-list">
                {error.error.data.message ===
                'There are some users only have this group, move them to another group to be able to delete this one!' ? (
                  <li>
                    {t(
                      'validation.validateResponse.notEmpty.userInGroup',
                      'Gruppen innehåller användare som bara kopplade till denna grupp, en användare måste tillhöra minst en grupp. Var god lägg till användaren i en annan grupp innan du tar bort denna grupp.'
                    )}
                  </li>
                ) : error.error.data.message ===
                  'You need firstly to replace group with another security space' ? (
                  <li>
                    {t(
                      'validation.validateResponse.notEmpty.removeConnection',
                      '  Den Security space du försöker ta bort har bara en grupp kopplad till sig. Varje Security Space behöver minst en grupp. Har du ej behörighet att se grupper? Kontakta din administratör.'
                    )}
                  </li>
                ) : error.error.data.message ===
                  'Role cannot be deleted because it is assigned to a user and there is no default role to replace it with.' ? (
                  <>
                    {t(
                      'validation.validateResponse.notEmpty.noDefaultRole',
                      'Rollen kan inte tas bort eftersom den är tilldelad en användare och det finns ingen standardroll att ersätta den med.'
                    )}
                  </>
                ) : (
                  <li>
                    {t(
                      'validation.validateResponse.notEmpty.roleOnUser',
                      'Rollen är kopplad till en användare, och kan inte raderas. Ta bort rollen från användaren/ användarna innan du tar bort rollen.'
                    )}
                  </li>
                )}
              </ul>
            </>
          </div>{' '}
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.notEmpty.buttonBack',
                'Tillbaka'
              )}
              onClick={() => setOpenFuncPopup(false)}
            />
          </div>{' '}
        </SmallModuleButton>
      </span>
    );
  };

  const ValidationUniqValues = () => {
    // lng
    const { t } = useTranslation();
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.validationUniqValues.title',
            'Åtgärden kan inte genomföras'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <div>
            <span className="error-list" style={{ textAlign: 'center' }}>
              {error.errorMsg ? (
                <>
                  {error.errorMsg.type === 'controllerHasPdp' ? (
                    <div style={{ textAlign: 'center' }}>
                      {t(
                        'validation.validateResponse.validationUniqValues.controllerHasPdp',
                        'Det finns personuppgiftsbehandlingar kopplade till {{ inputPersonalDataController }}. Byt {{ controllers }} eller ta bort personuppgiftsbehandlingarna för att kunna ta bort {{ inputPersonalDataController }}.',
                        {
                          inputPersonalDataController: error.errorMsg.tag,
                          controllers: userControllerTitle
                            ? userControllerTitle.value.toLocaleLowerCase()
                            : t(
                                'validation.validateResponse.validationUniqValues.controller',
                                'personuppgiftsansvarig'
                              ),
                        }
                      )}
                    </div>
                  ) : error.errorMsg.type === 'controllerHasName' ? (
                    <>
                      <div style={{ textAlign: 'center' }}>
                        {t(
                          'validation.validateResponse.validationUniqValues.controllerHasName',
                          '{{ controllers }} {{ errorMsg }} finns redan, det är ej tillåtet att lägga till dubbletter.',
                          {
                            errorMsg: error.errorMsg.tag,
                            controllers: userControllerTitle
                              ? userControllerTitle.value.toLocaleLowerCase()
                              : t(
                                  'validation.validateResponse.validationUniqValues.controller',
                                  'personuppgiftsansvarig'
                                ),
                          }
                        )}
                      </div>
                    </>
                  ) : error.error.data.message ===
                    'Information carrier must be connected to at least one security space' ? (
                    <p>
                      {t(
                        'validation.validateResponse.informationObject.hasNoSecuritySpace',
                        '{{ informationObject }} måste vara kopplad till minst ett {{securitySpace}}, koppla till ett annat {{securitySpace}} och gör sedan om åtgärden.',
                        {
                          informationObject: userInformationCarrierTitle
                            ? userInformationCarrierTitle.value
                            : 'Informations objekt',
                          securitySpace: userSecuritySpacesTitle
                            ? userSecuritySpacesTitle.value
                            : 'Security Space',
                        }
                      )}
                    </p>
                  ) : (
                    //defult error
                    <p>
                      {t(
                        'validation.validateResponse.validationUniqValues.nameAlredyUsed',
                        'Detta namnet finns redan inlagt i databasen, var god kontrollera namnet och försök sedan igen.'
                      )}
                    </p>
                  )}
                </>
              ) : (
                <p>
                  {t(
                    'validation.validateResponse.validationUniqValues.nameAlredyUsed',
                    'Detta namnet finns redan inlagt i databasen, var god kontrollera namnet och försök sedan igen.'
                  )}
                </p>
              )}
            </span>
          </div>{' '}
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.validationUniqValues.buttonBack',
                'Tillbaka'
              )}
              onClick={() => setOpenFuncPopup(false)}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const EmptySecuritySpace = () => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.emptySecuritySpace.title',
            'Åtgärden kan inte genomföras'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          {' '}
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.emptySecuritySpace.securitySpaceNotEmpty',
              'Du måste ta bort alla informationsObjekt från Security Space'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.emptySecuritySpace.buttonBack',
                'Tillbaka'
              )}
              onClick={() => setOpenFuncPopup(false)}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const NotAllowed = () => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.notAllowed.title',
            'Behörigheter saknas'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          {' '}
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.notAllowed.description',
              'Du saknar behörigheter. Var god kontakta er administratör för utökade rättigheter.'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.emptySecuritySpace.buttonBack',
                'Tillbaka'
              )}
              onClick={() => {
                //error.reset();
                setOpenFuncPopup(false);
                navigate(-1);
              }}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const NoDefaultRole = (hide) => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.noDefaultRole.title',
            'Standardroll saknas'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          {' '}
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.noDefaultRole.description',
              'Du behöver göra en av dina befintliga roller, till standardroll för att kunna fortsätta.'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.noDefaultRole.buttonBack',
                'Tillbaka'
              )}
              onClick={() => hide.hide()}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const NoDefaultGroup = (hide) => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.noDefaultGroup.title',
            'Standardgrupp saknas'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          {' '}
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.noDefaultGroup.description',
              'Du behöver göra om en annan grupp till standardgrupp för att kunna fortsätta.'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.noDefaultGroup.buttonBack',
                'Tillbaka'
              )}
              onClick={() => hide.hide()}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const DuplicateLogicValue = (logout) => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.duplicateLogicValue.title',
            'Åtgärden kan inte genomföras'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.duplicateLogicValue.description',
              'Samma svar för samma fråga kan inte lämnas in med två olika logiker (och & eller)'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.duplicateLogicValue.buttonBack',
                'Tillbaka'
              )}
              onClick={() => {
                setOpenFuncPopup(false);
              }}
            />{' '}
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const Unauthorized = (logout) => {
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.unauthorized.title',
            'Inloggning krävs'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.unauthorized.description',
              'Var god logga in för att se den här sidan.'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.unauthorized.buttonToLogin',
                'Logga in'
              )}
              onClick={() => {
                logout.logout();
              }}
            />{' '}
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const PasswordMismatchError = (hide) => {
    // if unkown errors, load this to user
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.wrongPassword.title',
            'Felaktigt lösenord'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.wrongPassword.description',
              'Nuvarand lösenord är fel, försök igen.'
            )}
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.wrongPassword.buttonBack',
                'Tillbaka'
              )}
              customColor="yellow"
              onClick={() => hide.hide()}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const DefaultPasswordError = (hide) => {
    const passwordPolicy = (
      <ul className="no-list-style">
        {passwordPolicyFromDB.length && (
          <li>
            {t(
              'validation.validateResponse.defaultPasswordError.passwordLength',
              `minst {{input}} tecken.`,
              {
                input: passwordPolicyFromDB?.length,
              }
            )}
          </li>
        )}
        {passwordPolicyFromDB.upperCase === 1 && (
          <li>
            {t(
              'validation.validateResponse.defaultPasswordError.upperCase',
              'en stor bokstav'
            )}
          </li>
        )}
        {passwordPolicyFromDB.lowerCase === 1 && (
          <li>
            {t(
              'validation.validateResponse.defaultPasswordError.lowerCase',
              'en liten bokstav'
            )}
          </li>
        )}
        {passwordPolicyFromDB.digits === 1 && (
          <li>
            {t(
              'validation.validateResponse.defaultPasswordError.digits',
              'en siffra'
            )}
          </li>
        )}
        {passwordPolicyFromDB.specialChars === 1 && (
          <li>
            {t(
              'validation.validateResponse.defaultPasswordError.specialChars',
              'ett specialtecken'
            )}
          </li>
        )}
      </ul>
    );
    // if unkown errors, load this to user
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.defaultPasswordError.title',
            'Nya lösenordet följer inte era lösenordsregler.'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <h3 style={{ textAlign: 'center', margin: '10px', fontWeight: 500 }}>
            {t(
              'validation.validateResponse.defaultPasswordError.description',
              'Lösenordet måste innehålla: '
            )}
          </h3>
          {passwordPolicy}
          <div className="center-everything" style={{ marginTop: '20px' }}>
            <Button
              buttonText={t(
                'validation.validateResponse.wrongPassword.buttonBack',
                'Tillbaka'
              )}
              customColor="yellow"
              onClick={() => hide.hide()}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  const ServerError = (hide) => {
    // if unkown errors, load this to user
    return (
      <span
        className="modal"
        style={{
          zIndex: 1200,
          padding: '0px',
          overflow: 'auto',
          minWidth: '0',
        }}
      >
        <SmallModuleButton
          title={t(
            'validation.validateResponse.serverError.title',
            'Ett okänt fel har påträffats'
          )}
          margin="0"
          bg="white"
          height="auto"
        >
          <p style={{ textAlign: 'center', margin: '10px' }}>
            {t(
              'validation.validateResponse.serverError.description',
              'Åtgärden kunde inte genomföras, var god försök igen. Om felet kvarstår, rapportera felet till'
            )}
            <span className="integrity-link">
              {t(
                'validation.validateResponse.serverError.support',
                ' support@dirsys.app'
              )}
            </span>
          </p>
          <div className="center-everything">
            <Button
              buttonText={t(
                'validation.validateResponse.serverError.buttonBack',
                'Tillbaka'
              )}
              customColor="yellow"
              onClick={() => hide.hide()}
            />
          </div>
        </SmallModuleButton>
      </span>
    );
  };

  return error.error ? (
    <div className="modal" style={{ padding: '0px' }}>
      {error.error.data &&
      error.error.data.message ===
        'Your account is not associated with any personal data process controllers.' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NoContent />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'Default role not found, cannot assign role to group members.' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NoDefaultRole hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message === 'Default group cannot be deleted' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NoDefaultGroup hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message === 'Select a security space to proceed!' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NoSecuritySpaces />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data &&
        error.error.data.message ===
          'The same answer for the same question cannot be submitted in two different logics (and & or)' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <DuplicateLogicValue />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'Information carriers should be associated to at least one security space!' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NoSecuritySpaces />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'You need firstly to empty Security space' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <EmptySecuritySpace />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'Role cannot be deleted because it is assigned to a user and there is no default role to replace it with.' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NotEmpty />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'There are some users only have this group, move them to another group to be able to delete this one!' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NotEmpty />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'There is some user are assigned to that role' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NotEmpty />
        </FunctionalPopUp>
      ) : error.error.data &&
        error.error.data.message ===
          'You need firstly to replace group with another security space' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NotEmpty />
        </FunctionalPopUp>
      ) : error.error.status === 403 ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <NotAllowed />
        </FunctionalPopUp>
      ) : error.error.status === 401 ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <Unauthorized logout={error.logout} />
        </FunctionalPopUp>
      ) : error.error.status === 409 ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <ValidationUniqValues />
        </FunctionalPopUp>
      ) : error.error.status === 400 ||
        error.error.status === 'BAD_REQUEST' ||
        error.error.status === 404 ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <ServerError hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      ) : error.error.status === 500 &&
        error.error.data?.message ===
          'Error updating password: Old password is incorrect' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <PasswordMismatchError hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      ) : error.error.status === 500 &&
        error.error.data?.message ===
          'Error updating password: HTTP 400 Bad Request' ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <DefaultPasswordError hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      ) : error.error.status === 500 ? (
        <FunctionalPopUp trigger={openFuncPopup}>
          <ServerError hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      ) : (
        <FunctionalPopUp trigger={openFuncPopup}>
          <ServerError hide={() => setOpenFuncPopup(false)} />
        </FunctionalPopUp>
      )}
    </div>
  ) : (
    <></>
  );
};
export { WrapperError };
