import React from 'react';
import TooltipMUI from '@mui/material/Tooltip';
import { Zoom } from '@mui/material';
import { sanitizeHtml } from '../../../shared/function/function';

// Imoprt of CSS, SASS
import tooltip_icon from '../../../shared/images/tooltip_icon.png';

import './style.css';

const MUITooltip = (props: {
  content: any;
  disabled?: boolean;
  noStyle?: boolean;
  linkTo?: string;
  place?: any;
  images?: any;
  navigateClick?: any;
  muiIcon?: any;
  offset?: number[];
  questionMarkIcon?: boolean;
  white?: boolean;
}) => {
  const {
    content,
    place,
    linkTo,
    images,
    navigateClick,
    noStyle,
    muiIcon,
    disabled,
    questionMarkIcon,
    white,
  } = props;

  const placement = place ? place : 'right';

  const data = (
    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
  );

  return (
    <span className={noStyle ? '' : 'tooltip-container integrity-loga-tooltip'}>
      <TooltipMUI
        data-cy={content}
        enterDelay={100}
        leaveDelay={200}
        disableHoverListener={disabled}
        disableInteractive={disabled}
        title={data}
        placement={placement}
        arrow
        TransitionComponent={Zoom}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: props.offset ? props.offset : [0, 10],
                },
              },
            ],
          },
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'black',
              color: 'white',
              fontSize: '.9rem',
              fontFamily: 'Nunito Sans',
            },
          },
          arrow: {
            sx: {
              color: 'black',
            },
          },
        }}
      >
        <a target="_blank" rel="noopener noreferrer" href={linkTo}>
          <li
            style={{
              listStyle: 'none',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={navigateClick ? navigateClick : null}
          >
            {images && <img src={images} alt="tooltip-icon" />}
            {muiIcon && (
              <span style={{ margin: '5px', display: 'flex' }}>{muiIcon}</span>
            )}
            {!images && !muiIcon && questionMarkIcon && (
              <span style={{ margin: '5px', display: 'flex' }}>
                <img
                  style={white ? { filter: 'invert(1' } : {}}
                  src={tooltip_icon}
                  alt="question icon"
                ></img>
              </span>
            )}
          </li>
        </a>
      </TooltipMUI>
    </span>
  );
};

export default MUITooltip;
