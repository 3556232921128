import { http } from '../backendUserAdmin';
const ic = 'personal-data-process';

// questions //

const getQuestions = (context: string) => {
  return http.get(`${ic}/questions/context/${context}`);
};

const getControlPlansConnectedToQuestion = (id: number, context: string) => {
  return http.get(`${ic}/questions/${id}/context/${context}`);
};

const postQuestions = (newQuestion: any, context: string) => {
  return http.post(`${ic}/questions/context/${context}`, newQuestion);
};

const postInformationCarrierList = (list: any) => {
  return http.post(`${ic}/information-carriers/multi`, list);
};

const putQuestions = (updatedQuestion: any, context: string) => {
  const id = updatedQuestion.id;
  return http.put(`${ic}/questions/${id}/context/${context}`, updatedQuestion);
};

const deleteQuestion = (q: any) => {
  const id = q.id;
  return http.delete(`${ic}/questions/${id}`);
};

// for sorting of questions
const patchOrder = (context: string, sort: any) => {
  return http.patch(`${ic}/questions/context/${context}/sort`, sort);
};

// get information carrier to select i dropdown

const getIc = () => {
  return http.get(`${ic}/security/all`);
};

const getOneIc = (id: number) => {
  return http.get(`${ic}/security/${id}`);
};

const getAllIcAnswers = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(
    `${ic}/security/all-answers?page=${page}&size=${elementSize}`
  );
};

const putIc = (id: number, result: any) => {
  return http.put(`${ic}/${id}/security/answer`, result);
};

// get risk
const getRisk = (icId: number) => {
  return http.get(`${ic}/security/${icId}/information-carrier-risk/risks`);
};

// post risk
const postRisk = (newRisk: any, icId: number) => {
  return http.post(
    `${ic}/security/${icId}/information-carrier-risk/risks`,
    newRisk
  );
};

// put risk
const putRisk = (updatedRisk: any, icId: number, riskId: number) => {
  return http.put(
    `${ic}/security/${icId}/information-carrier-risk/risks/${riskId}`,
    updatedRisk
  );
};

// delete risk
const deleteRisk = (riskId: number, icId: number) => {
  return http.delete(`${ic}/security/${icId}/information-carrier-risk/risks/${riskId}`);
};

//*** Measure */

// get measure for pagination
const getMeasure = (
  informationCarrierId: number,
  page: number,
  fetchAll?: any
) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 5;  
  return http.get(
    `${ic}/security/risk-measure/${informationCarrierId}?page=${page}&size=${elementSize}`
  );
};
const getMeasureSortedByHeader = (
  icId: number,
  headerId: string,
  order: string,
  page: number,
  fetchAll: any
) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 5;
  return http.get(
    `${ic}/security/risk-measure/${icId}/sort/${headerId}/by/${order}?page=${page}&size=${elementSize}`
  );
};

//  measure
const postMeasure = (id: number, icId: number, objectToBackend: any) => {
  return http.post(`${ic}/security/${icId}/risks/${id}/measures`, objectToBackend);
};
//  measure
const putMeasure = (icId: number, measureId: number, objectToBackend: any) => {
  return http.put(
    `${ic}/security/${icId}/risks/measures/${measureId}`,
    objectToBackend
  );
};
//  measure
const deleteMeasure = (icId: number, measureId: number) => {
  return http.delete(`${ic}/security/${icId}/risks/measures/${measureId}`);
};

// filter ic

const postFilterIc = (searchParam: any, page: number, fetchAll?: number) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.post(
    `${ic}/security/filter?page=${page}&size=${elementSize}`,
    searchParam
  );
};

// user current informationCarrier
// get security spaces
const getCurrentInformationCarriers = (params?: any) => {
  return http.get(
    `${ic}/security-space/my-security-space/information-carriers${params ? '?ss=' + params : ''}`
  );
};
//***security spaces***

// get all, to use as options in dropdown, without pagination

// get security spaces
const getAllSecuritySpaces = () => {  
  return http.get(`${ic}/security-space/all`);
};

// get security spaces
const getCurrentSecuritySpaces = () => {
  return http.get(`${ic}/security-space/my-security-space`);
};

// get security spaces
const getCurrentSecuritySpacesWithCreate = () => {
  return http.get(`${ic}/security-space/my-security-space?filterByPermission=CREATE`);
};

// get security spaces
const getSecuritySpaces = (page: number, fetchAll?: any) => {
  const elementSize = fetchAll && fetchAll > 0 ? fetchAll : 25;
  return http.get(`${ic}/security-space?page=${page}&size=${elementSize}`);
};

// post security spaces
const postSecuritySpaces = (newSecuritySpace: any) => {
  return http.post(`${ic}/security-space`, newSecuritySpace);
};

// put security spaces
const putSecuritySpaces = (updateSecuritySpace: any, id: number) => {
  return http.put(`${ic}/security-space/${id}`, updateSecuritySpace);
};

// delete security spaces
const deleteSecuritySpaces = (id: number) => {
  return http.delete(`${ic}/security-space/${id}`);
};

const removeInformationCarrierFromSpecificSecuritySpace = (
  securitySpaceId: number,
  informationCarrierId: number
) => {
  return http.delete(
    `${ic}/security-space/${securitySpaceId}/information-carriers/${informationCarrierId}`
  );
};

export default {
  getQuestions,
  getControlPlansConnectedToQuestion,
  putQuestions,
  patchOrder,
  postQuestions,
  postInformationCarrierList,
  deleteQuestion,
  getIc,
  getOneIc,
  getAllIcAnswers,
  putIc,
  postRisk,
  getRisk,
  putRisk,
  deleteRisk,
  postMeasure,
  deleteMeasure,
  putMeasure,
  getSecuritySpaces,
  postSecuritySpaces,
  putSecuritySpaces,
  deleteSecuritySpaces,
  removeInformationCarrierFromSpecificSecuritySpace,
  getAllSecuritySpaces,
  getCurrentSecuritySpaces,
  getMeasure,
  getMeasureSortedByHeader,
  postFilterIc,
  getCurrentInformationCarriers,
  getCurrentSecuritySpacesWithCreate
};
