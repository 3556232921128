import { http } from '../backendUserAdmin';
const tenantInformationUrl = '/tenant-information';

const getLicenses = () => {
  return http.get(`/license-counter/active`);
};

const getAutoProvisioningAllowed = () => {
  return http.get(`access-management/auto-provisioning-allowed`);
};

const getTenantInformation = (tenantId: string) => {
  return http.get(`${tenantInformationUrl}/${tenantId}`);
};

const getIdentityProvider = () => {
  return http.get(`access-management/idp`);
};

const postMetaData = (obj: {fromUrl: string, providerId: string}) => {
  return http.post(`access-management/import-idp-config`, obj);
};

const postIdp = (obj: any) => {
  return http.post(`access-management/idp`, obj);
};

const putIdp = (obj: any, id: number) => {  
  return http.put(`access-management/idp/${id}`, obj);
}
const deleteIdp = (id: number) => {
  return http.delete(`access-management/idp/${id}`);
};

const getSSOUpdateRequired = () => {
  return http.get(`access-management/sso-update-required`);
};

const enableAutoProvisioningFlow = () => {
  return http.get(`access-management/reset-and-update-sso`);
};
 
export default {
  getTenantInformation,
  getIdentityProvider,
  postMetaData,
  postIdp,
  putIdp,
  deleteIdp,
  getLicenses,
  getAutoProvisioningAllowed,
  getSSOUpdateRequired,
  enableAutoProvisioningFlow
};
