// Import of packages
import { Outlet } from 'react-router-dom';

// Import of context, functions, hook ect...
import GateKeeper from '../shared/Permissions/GateKeeper';
import Forbidden from './Forbidden';

const ProtectedRoute = ({ allowedRoles }) => {
  // get access from context

  // Render
  return (
    <GateKeeper actions={allowedRoles} fallback={<Forbidden />}>
      <Outlet />
    </GateKeeper>
  );
};
export default ProtectedRoute;
