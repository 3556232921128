import React from 'react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { configure } from './shared/api/backendUserAdmin';
import KeycloakService from './shared/provider/KeycloakService';
import { App } from './App';
import { loadingMarkup } from './shared/function/function';
import { UserDetailsProvider } from './shared/provider/UserDetailsProvider';
import preval from 'preval.macro';

// css for snackbar needed in evry view
import './components/snackbar/snackbar.css';
import { TenantProvider } from './shared/provider/TenantProvider';

// get timpestamp on build
const buildTimestamp = preval`module.exports = new Date().toLocaleString();`;
console.log('DirSys Version: 6.5.0, ', buildTimestamp);
const render = () =>
  ReactDOM.render(
    <Suspense fallback={loadingMarkup}>
      <TenantProvider>
        <UserDetailsProvider>
          <App />
        </UserDetailsProvider>
      </TenantProvider>
    </Suspense>,
    document.getElementById('root')
  );

KeycloakService.initKeycloak(render);
configure();
