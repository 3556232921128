import React, { useState, createContext } from "react";

export const ActiveModeContext = createContext<any>(null);

export const ActiveModeProvider = (props: { children?: React.ReactChild }) => {
	const { children } = props;
	const [inActiveMode, setInActiveMode] = useState<boolean>(false);

	return (
		<ActiveModeContext.Provider value={[inActiveMode, setInActiveMode]}>
			{children}
		</ActiveModeContext.Provider>
	);
};
