// Imoprt of CSS, SASS
import './SmallModuleButton.css';

export const SmallModuleButton = (props: {
  title: string;
  description?: string;
  clickHandler?: any;
  children?: any;
  margin?: string;
  bg?: string;
  height?: string;
  customCssClass?: string;
}) => {
  // Destruct props
  const {
    title,
    clickHandler,
    description,
    children,
    margin,
    bg,
    height,
    customCssClass,
  } = props;

  // Render
  return (
    <div
      className={
        clickHandler
          ? 'small-button click'
          : customCssClass
          ? 'small-button customCssClass'
          : 'small-button '
      }
      style={{ margin, height: `${height}` }}
      onClick={clickHandler}
    >
      <div className="title-module">
        <h2>{title}</h2>
      </div>
      <div
        className="description-module-small"
        style={{ backgroundColor: `${bg}` }}
      >
        {description && description}
        {children}
      </div>
    </div>
  );
};
