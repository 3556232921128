// import packages
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import of hooks
import useOnClickOutside from '../../shared/hooks/useOnClickOutside';

// import SVG as React component
import { ReactComponent as Profile } from '../../shared/images/profile.svg';

// import component
import Tooltip from '../../components/Tooltip/Tooltip';

// import context
import { ActiveModeContext } from '../../shared/provider/ActiceModeProvider';

// import css
import './admin.css';

const EditProfileKeycloak = (props: { logout: any }) => {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = () => setShowMenu(false);
  useOnClickOutside(ref, handleClickOutside);
  const navigate = useNavigate();
  const { logout } = props;

  // lng
  const { t } = useTranslation();

  const toolTipContentProfile = t(
    'appAdmin.editProfileKeycloak.tooltip',
    'Ändra personliga inställningar'
  );
  const [inActiveMode] = useContext<any>(ActiveModeContext);

  const toolTip = t('appAdmin.editProfileKeycloak.logout', 'Logga ut');

  return (
    <div
      className="edit-profile"
      data-tip={toolTipContentProfile}
      data-for="profile"
      data-cy="button-testId-account-button"
      id="profile"
      style={{ paddingLeft: '8px', scale: '.8', height: '50px' }}
    >
      {/* svg image as component */}
      <span onClick={() => setShowMenu(true)} style={{ cursor: 'pointer' }}>
        <Profile />
      </span>

      {showMenu && (
        <div className="dropdown-content" ref={ref}>
          <button onClick={() => navigate('/reset-password')}>
            {t('appAdmin.editProfileKeycloak.password', 'Lösenord')}
          </button>
          <hr />
          {inActiveMode ? (
            <>
              <span className="center-everything" style={{ width: '100%' }}>
                <Tooltip
                  id="tooltip-dropdown-dpia"
                  extraStyle={{ fontSize: '11px', margin: '5px 0 0 -20px' }}
                  addCss="left"
                  tooltipContent={toolTip}
                  contentToDisplay={
                    <button
                      style={{
                        fontStyle: 'italic',
                        color: '#7d7d7d',
                        width: '100%',
                      }}
                      disabled
                    >
                      {t('appAdmin.editProfileKeycloak.logout', 'Logga ut')}
                    </button>
                  }
                />
              </span>
            </>
          ) : (
            <button
              data-cy="button-testId-logout-button"
              onClick={() => {
                logout();
              }}
            >
              {t('appAdmin.editProfileKeycloak.logout', 'Logga ut')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default EditProfileKeycloak;
