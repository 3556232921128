// Dashboard
const WelcomeScreen: string = '/';
const Insights: string = '/insights';

// Integrity
const IndexListView: string = '/register-list';
const NewPersonalDataProcessingView: string = '/new-personal-data-process';
const IndexModule: string = '/integrity-module';
const InvestigateView: string = '/investigate-need-for-dpia';
const DpiaView: string = '/dpia';

// Security
const SecurityModule = '/security-module';
const IcPageView = '/information-carrier-list';
const DetailedInformationCarrierView = `/detailed-information-carrier`;

// Framework
const SecurityFramework = '/security-framework';

// Admin
const AppAdmin: string = '/app-administration';
const UserAdmin: string = '/user-administration';
const Unauthorized: string = '/unauthorized';
const Forbidden: string = '/forbidden';
const ServerIsDown: string = '/server-down';
const Error: string = '*';
// const Logout: string = '/logout';

//Account
const ResetPassword: string = '/reset-password';

export default {
  WelcomeScreen,
  IndexListView,
  NewPersonalDataProcessingView,
  IndexModule,
  AppAdmin,
  Unauthorized,
  Forbidden,
  ServerIsDown,
  Error,
  DpiaView,
  InvestigateView,
  SecurityModule,
  IcPageView,
  DetailedInformationCarrierView,
  UserAdmin,
  SecurityFramework,
  Insights, 
  // Logout,
  ResetPassword
};
