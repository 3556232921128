import { useContext } from 'react';
import { UserDetailsContext } from '../provider/UserDetailsProvider';
import { findCommonElement } from '../function/function';
import { hasAccess } from './AccessControllFunction';

/**
 * @param children: JSX to display if allowed, JSX shuold be placed between opening and closing tags @required
 * @param actions: What permissions is needed to access content - String, ex. 'USER_READ'  @required
 * @param fallback: if passed down, this will render if you get stopped at the gate (JSX)
 * @returns
 */

/** ex.
        <GateKeeper  actions={['DPIA_DELETE']} >

        </GateKeeper>
 */
const GateKeeper = (props: {
  children: any;
  actions: any;
  fallback?: any;
  inputAction?: any;
}) => {
  const { actions, fallback, inputAction } = props;
  // userDetails: Information about current users controllers, spaces, permissions ect.
  // get access from context
  const { userPermission } = useContext(UserDetailsContext);

  if (inputAction) {
    if (findCommonElement(inputAction, actions)) {
      return <>{props.children}</>;
    }
    return fallback ? fallback : null;
  } else {
    if (hasAccess(userPermission, actions)) {
      return <>{props.children}</>;
    }
    return fallback ? fallback : null;
  }
};

export default GateKeeper;
