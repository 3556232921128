import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { useForm } from 'src/shared/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'src/components/formInput/FormInput';
import {
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from '@mui/material';
import MUITextField from 'src/components/MUICustom/MUITextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import userAdminService from 'src/shared/api/services/userAdminService';
import { serverError } from 'src/shared/function/function';
import { WrapperError } from 'src/shared/validation/validateResponse';
import { UserContext } from 'src/shared/provider/UserProvider';
import { userPassword } from 'src/shared/interface/Interface';
import SpinnerDirsys from 'src/shared/SpinnerDirsys';

// Define the type for the state
interface State {
  username: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

// Define the type for each question
interface Question {
  id: keyof State; // The id should match the keys of the state
  name: string;
  type: string;
  placeholder: string;
  tooltip: string;
  obligatory: boolean;
  validateErrorKey: string;
  autocomplete: string;
}
interface Errors {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  [key: string]: string | undefined;
}
const ResetPassword = () => {
  const { passwordPolicyFromDB } = useContext(UserContext);
  const [feedback, setFeedback] = useState('');
  const [validateError, setValidateError] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [passwordState, setPasswordState] = useState<State>({
    username: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  // manage password visibility
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
    username: false,
  });

  const { t } = useTranslation();
  const delay: number = 1750;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setPasswordState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle visibility toggle
  const togglePasswordVisibility = (id: keyof State) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const questions: Question[] = [
    {
      id: 'currentPassword',
      name: t(
        'ResetPassword.question.currentPasswor.title',
        'Nuvarande lösenord'
      ),
      type: 'password',
      placeholder: '',
      tooltip: t(
        'ResetPassword.question.currentPasswor.tooltip',
        'Ange nuvarande lösenord'
      ),
      obligatory: true,
      validateErrorKey: '',
      autocomplete: 'current-password',
    },

    {
      id: 'newPassword',
      name: t('ResetPassword.question.newPassword.title', 'Nytt lösenord'),
      type: 'password',
      placeholder: '',
      tooltip: t(
        'ResetPassword.question.newPassword.tooltip',
        'Ange nytt lösenord'
      ),
      obligatory: true,
      validateErrorKey: '',
      autocomplete: 'new-password',
    },
    {
      id: 'confirmNewPassword',
      name: 'Bekräfta nytt lösenord',
      type: 'password',
      placeholder: '',
      tooltip: t(
        'ResetPassword.question.confirmNewPassword.tooltip',
        'Ange nya lösenordet igen'
      ),
      obligatory: true,
      validateErrorKey: '',
      autocomplete: 'new-password',
    },
  ];

  const reset = () => {
    setPasswordState({
      username: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setValidateError({});
    setTimeout(() => {
      setFeedback('');
    }, delay + 250); // some extra time for animation
  };

  const validate = (): Errors => {
    const newErrors: Errors = {};

    if (
      !passwordState.currentPassword ||
      passwordState.currentPassword.length === 0
    ) {
      newErrors.currentPassword = t(
        'ResetPassword.question.currentPasswor.validation',
        'Nuvarande lösenord är obligatoriskt.'
      );
    }
    if (!passwordState.newPassword) {
      newErrors.newPassword = t(
        'ResetPassword.question.newPassword.validation',
        'Nytt lösenord är obligatoriskt.'
      );
    } else if (
      passwordState.newPassword.length < passwordPolicyFromDB?.length
    ) {
      newErrors.newPassword = t(
        'validation.validateResponse.defaultPasswordError.passwordLength',
        `Lösenordet måster vara minst {{input}} tecken långt.`,
        {
          input: passwordPolicyFromDB?.length,
        }
      );
    }
    if (passwordState.newPassword !== passwordState.confirmNewPassword) {
      newErrors.confirmNewPassword = t(
        'ResetPassword.question.confirmNewPassword.validation',
        'Lösenorden matchar inte.'
      );
    }
    return newErrors;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const validationErrors = validate();
    setValidateError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      const password: userPassword = {
        oldPassword: passwordState.currentPassword,
        newPassword: passwordState.newPassword,
        confirmPassword: passwordState.confirmNewPassword,
      };

      try {
        await userAdminService.putUserPassword(password);
        setFeedback(
          t('resetPassword.feedback.success', 'Ditt lösenord har ändrats.')
        );
        reset();
      } catch (error: any) {
        // error to null
        setError(null);
        if (error.response === undefined) {
          serverError();
        } else {
          setError(error.response);
        }
      }
      setIsLoading(false);
    } else {
      // validation failed
      console.log('Form validation failed. Please check errors.');
    }
  };

  return (
    <div className="center-everything" style={{ flexDirection: 'column' }}>
      <h3 className="center-everything" style={{ margin: '50px' }}>
        {t('resetPassword.title', 'Byt lösenord')}
      </h3>
      <Snackbar
        data-cy="snackbarMui-testId"
        open={feedback !== ''}
        autoHideDuration={delay}
        message={feedback}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            background: '#2F4858',
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      />
      {error ? <WrapperError error={error} /> : null}
      {isLoading ? (
        <SpinnerDirsys />
      ) : (
        <form onSubmit={handleSubmit} noValidate>
          {/* Hidden username field for accessibility */}
          <input
            type="text"
            id="username"
            name="username"
            value={passwordState.username}
            onChange={handleChange}
            autoComplete="username"
            style={{ display: 'none' }}
          />
          {questions.map((question) => (
            <div
              key={question.id}
              style={{ margin: '20px' }}
              className="margin-zero"
            >
              <TextField
                id={question.id}
                label={question.name}
                type={showPassword[question.id] ? 'text' : 'password'} // toggle type to hide/see input
                placeholder={question.placeholder}
                value={passwordState[question.id]} // bind the value to the state
                onChange={handleChange}
                autoComplete={question.autocomplete}
                fullWidth
                required={question.obligatory}
                error={!!validateError[question.id]}
                helperText={validateError[question.id]} // show error
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={`toggle ${question.id} visibility`}
                        onClick={() => togglePasswordVisibility(question.id)}
                        edge="end"
                      >
                        {showPassword[question.id] ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ))}
          <div className="center-everything" style={{ margin: '20px 0' }}>
            <Button
              variant="contained"
              sx={{ height: '38px', margin: '10px' }}
              color="secondary"
              onClick={() => reset()}
              data-cy="button-testId-cancel-button"
            >
              {t('resetPassword.button.cancel', 'Avbryt')}
            </Button>
            <Button
              variant="contained"
              sx={{ height: '38px', margin: '10px' }}
              color="primary"
              type="submit"
              data-cy="button-testId-saveOrUpdate-button"
            >
              {t('resetPassword.button.save', 'Spara')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
