import DOMPurify from 'dompurify';

// Import of components
import SpinnerDirsys from '../SpinnerDirsys';

// statusbar

function countFulfills(data) {
  const count = data.reduce(
    (acc, curr) => {
      const fulfills = curr.fulfills;
      if (fulfills === 0) {
        acc.notAnswered += 1;
      } else if (fulfills === 1) {
        acc.notRelevant += 1;
      } else if (fulfills === 2) {
        acc.red += 1;
      } else if (fulfills === 3) {
        acc.yellow += 1;
      } else if (fulfills === 4) {
        acc.green += 1;
      }
      return acc;
    },
    {
      notAnswered: 0,
      red: 0,
      yellow: 0,
      green: 0,
      notRelevant: 0,
    }
  );
  return count;
}

// sort alphabetically
const sortAlphabetically = (array, key, asc) => {
  // const res = array.sort((a, b) => a[key].localeCompare(b[key]));
  array.sort((a, b) => {
    const res = asc ? 1 : -1;
    return res * a[key].localeCompare(b[key]);
  });
};

function upperCaseFirst(str) {
  return str.charAt(0).toUpperCase() + str.substring(1);
}

function tooLongString(value, regex) {
  // need to add regex in function call ex. '/.{1,26}/g'
  let parts = value.match(regex);
  let arr = [];
  parts.map((element, idx) => {
    return parts.length - 1 === idx
      ? arr.push(element)
      : arr.push(element + '- <br / >');
  });
  return arr.join('');
}

//if server is down, go to server is down page
const serverError = () => {
  if (window.location.pathname !== '/server-down') {
    window.location.replace('/server-down');
  }
};

// style for react-select
const colourStyles = {
  option: (styles, { isDisabled, isFocused, isSelected, data }) => {
    return {
      ...styles,
      height: '50px',
      borderBottom: '1px dotted #f0a4845b',
      cursor: isFocused && 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#F0A584' : null),
      },
      backgroundColor: isFocused ? '#ecbbab49' : null,
      color: '#000000',
      ':first-of-type': {
        backgroundColor: data.id === 9999999 ? '#F1F1F1' : null,
      },
    };
  },
};

function last(array) {
  return array[array.length - 1];
}
// create NEW object - yes & no need to come from a react component to make use of lng
const createObjectFromInput = (formState, yes, no, framework) => {
  let answerFromUser = Object.keys(formState.inputs)
    .map((key, i) => {
      if (key !== 'dpia' && key !== 'name' && key != 'securitySpaces') {
        return {
          // framework is used for security framework, to seperate question with type 'headers' from other question
          ...(framework &&
            framework[i].type === 'headers' && {
              frameworkTitleQuestion: true,
            }),
          id: Number(key),
          answers:
            //if type boolean, if value, (true), set to "Ja" else 'Nej'
            typeof formState.inputs[key].value === 'boolean'
              ? [
                  {
                    value: formState.inputs[key].value ? yes : no,
                  },
                ]
              : //if object, map over
              typeof formState.inputs[key].value === 'object'
              ? formState.inputs[key].value.length !== 0
                ? formState.inputs[key].value.map((el) => {
                    return {
                      value: el.name ? el.name : el.value,
                    };
                  })
                : [{ value: null }]
              : formState.inputs[key].value !== '' &&
                formState.inputs[key].value !== undefined &&
                formState.inputs[key].value.length !== 0
              ? [
                  {
                    value: formState.inputs[key].value,
                  },
                ]
              : [
                  {
                    value: null,
                  },
                ],
        };
      }
    })
    .filter((item) => item !== undefined);
  return answerFromUser;
};
// edit object - yes & no need to come from a react component to make use of lng
const createObjectEdit = (inputObject, yes, no) => {
  let result = Object.keys(inputObject)
    .map((key, i) => {
      // moved code to own const, se link https://eslint.org/docs/rules/no-prototype-builtins
      const hasValueProperty = Object.prototype.hasOwnProperty.call(
        inputObject[key],
        'value'
      );
      if (
        key !== 'name' &&
        key !== 'informationCarriers' &&
        key !== 'controllers' &&
        key !== 'investigation'
      ) {
        return {
          id: Number(key),
          answers:
            typeof inputObject[key].value === 'object' &&
            inputObject[key].value[0] !== undefined
              ? inputObject[key].value.map((el) => {
                  return {
                    value: el.name,
                  };
                })
              : inputObject[key].value !== '' &&
                inputObject[key].value !== undefined &&
                !hasValueProperty
              ? [
                  {
                    value: inputObject[key].value,
                  },
                ]
              : hasValueProperty && typeof inputObject[key].value === 'boolean'
              ? [
                  {
                    value: inputObject[key].value ? yes : no,
                  },
                ]
              : typeof inputObject[key].value === 'string'
              ? [
                  {
                    value: inputObject[key].value,
                  },
                ]
              : [
                  {
                    value: null,
                  },
                ],
        };
      }
    })
    .filter((item) => item !== undefined);
  return result;
};

const decideMode = (dpia, setMode) => {
  let box = [];
  dpia.forEach((el) => {
    const hasValueProperty = Object.prototype.hasOwnProperty.call(
      el.answers[0],
      'value'
    );
    box.push(hasValueProperty);
  });
  box.includes(true) ? setMode('answers') : setMode('questions');
};

function getRandomId() {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function euroJackpotGen() {
  let cash = [];
  let star = [];

  do {
    let num = Math.floor(Math.random() * 50) + 1;
    if (!cash.includes(num)) {
      cash.push(num);
    }
  } while (cash.length < 5);

  do {
    let stNum = Math.floor(Math.random() * 12) + 1;
    if (!star.includes(stNum)) {
      star.push(stNum);
    }
  } while (star.length < 2);

  if (cash.length === 5 && star.length === 2) {
    return {
      number: cash,
      starNumber: star,
    };
  } else return 'Don´t gamble this weekend';
}

const loadingMarkup = (
  <div
    className="center-everything"
    style={{ height: ' 100vh', textAlign: 'initial' }}
  >
    <SpinnerDirsys />
  </div>
);

const getChanges = (previous, current) => {
  if (isPrimitive(previous) && isPrimitive(current)) {
    if (previous === current) {
      return '';
    }

    return current;
  }

  if (isObject(previous) && isObject(current)) {
    const diff = getChanges(Object.entries(previous), Object.entries(current));

    return diff.reduce((merged, [key, value]) => {
      return {
        ...merged,
        [key]: value,
      };
    }, {});
  }

  const changes = [];

  if (JSON.stringify(previous) === JSON.stringify(current)) {
    return changes;
  }

  for (let i = 0; i < current.length; i++) {
    const item = current[i];

    if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
      changes.push(item);
    }
  }

  return changes;
};

const sortContent = (array, key) => {
  let result = array.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
  return result;
};

const typeOf = (o) => Object.prototype.toString.call(o);
const isObject = (o) =>
  o !== null &&
  !Array.isArray(o) &&
  typeOf(o).split(' ')[1].slice(0, -1) === 'Object';

const isPrimitive = (o) => {
  switch (typeof o) {
    case 'object': {
      return false;
    }
    case 'function': {
      return false;
    }
    default: {
      return true;
    }
  }
};

// get answers
const getAnswers = (input, htmlAllowed, yes, no) => {
  return input.answers[0]?.value ? (
    input.type === 'boolean' ? (
      input.answers[0]?.value === 'No' || input.answers[0]?.value === 'Nej' ? (
        no
      ) : (
        yes
      )
    ) : input.type === 'link' ? (
      input.answers.length !== 0 ? (
        <a target="_blank" href={input.answers[0]?.value}>
          {'Länk'}
        </a>
      ) : (
        <p>-</p>
      )
    ) : (
      <ul className="answers-pdp">
        {input.answers.map((e) => {
          return (
            <li key={`${e.value}-${e.id}-${getRandomId()}`}>
              {e.value
                ? htmlAllowed
                  ? e.value
                  : getAnswerWithoutHtml(e.value)
                : ''}
            </li>
          );
        })}
      </ul>
    )
  ) : (
    '-'
  );
};

// split array
const splitArray = (array, size) => {
  const chunked = [];
  let index = 0;
  if (array) {
    while (index < array.length) {
      chunked.push(array.slice(index, index + size));
      index += size;
    }
    return chunked;
  } else return [];
};
// ex use const splitArr = splitArray(INPUT_ARRAY, NUMBER_PER_CHUNK);

// Function definition with passing two arrays
function findCommonElement(array1, array2) {
  // Loop for array1
  for (let i = 0; i < array1.length; i++) {
    // Loop for array2
    for (let j = 0; j < array2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (array1[i] === array2[j]) {
        // Return if common element found
        return true;
      }
    }
  }

  // Return if no common element exist
  return false;
}

const isParentFalse = (currentQuestion, questions, parentOption, context) => {
  let result = false;
  // ex use in Icrow-component: isParentFalse(v,informationCarrier?.questions,maxHeadersQuestionsNumber)
  const parentQuestion =
    currentQuestion.parentId !== 0 &&
    questions.filter((el) => el.id === currentQuestion.parentId);

  if (parentQuestion[0]) {
    if (context) {
      result =
        [parentQuestion[0]?.answer]?.filter((o1) =>
          currentQuestion?.parentOptions?.some((o2) => o1.id === o2.id)
        ).length !== 0
          ? false
          : true;
    } else if (parentOption && parentQuestion[0]?.type !== 'boolean') {
      result =
        parentQuestion[0]?.answers?.filter((o1) =>
          currentQuestion?.parentOptions?.some((o2) => o1.value === o2.name)
        ).length !== 0
          ? false
          : true;
    } else if (
      currentQuestion.parentId !== 0 &&
      (parentQuestion[0]?.answers[0].value === false ||
        parentQuestion[0]?.answers[0].value === 'no' ||
        parentQuestion[0]?.answers[0].value === 'nej' ||
        parentQuestion[0]?.answers[0].value === 'Nej' ||
        parentQuestion[0]?.answers[0].value === 'No')
    ) {
      result = true;
    }
    return result;
  }
};

const findParentOption = (inputParentOptions, parentHasOptions) => {
  // ex.    findParentOption(overallEditState[parentId], parentOptions)
  return inputParentOptions?.filter((o1) =>
    parentHasOptions?.some((o2) => o1.name === o2.value)
  );
};

const getAnswerWithoutHtml = (input) => {
  if (input && typeof input === 'string') {
    const removeHtml = input.replace(/<[^>]+>/g, '');

    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string as XML
    const xmlDoc = parser.parseFromString(removeHtml, 'text/html');

    // Get the decoded text content from the parsed XML
    const decodedString = xmlDoc.documentElement.textContent;
    return decodedString;
  } else return;
};

const includesAll = (fail, arr) => {
  return arr.reduce((accumulator, current) => {
    accumulator && fail.includes(current.answer);
  }, true);
};

const excludeHtml = (input) => {
  return input ? input.replace(/(<([^>]+)>)/gi, '') : '';
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

// sanitize html
// KEEP_CONTENT: Specifies whether the content inside disallowed tags should be kept or removed.
// ALLOWED_TAGS: Specifies which HTML tags are allowed in the sanitized output.
// ALLOWED_ATTR: Specifies which attributes are allowed on the allowed HTML tags.
// ex use:       <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html}} />
const sanitizeHtml = (html) => {
  const sanitizeOptions = {
    ALLOWED_TAGS: [
      'h1',
      'h2',
      'p',
      'strong',
      'em',
      'u',
      's',
      'span',
      'br',
      'ol',
      'ul',
      'li',
      'a',
      '#text',
    ],
    ALLOWED_ATTR: [
      'style',
      'class',
      'className',
      'href',
      'target',
      'data-list',
      'contenteditable',
      'rel',
      'list',
    ],
    KEEP_CONTENT: false,
  };
  return DOMPurify.sanitize(html, sanitizeOptions);
};

// function to format data for roles render
const formatData = (data) => {
  const formattedData = data.reduce((acc, item) => {
    const [key, value] = item.split('_');
    const existingItem = acc.find((el) => el.key === key);

    if (existingItem) {
      existingItem.value.push(value);
    } else {
      acc.push({ key, value: [value] });
    }

    return acc;
  }, []);

  return formattedData;
};

const testAgainstRegexp = (url, type) => {
  let pattern;

  // Choose the regex pattern based on the type
  switch (type) {
    case 'singleSignOnServiceUrl':
      pattern = /^https:\/\/.*\/saml2$/;
      break;
    case 'generalUrl':
      pattern = /^https?:\/\/.*/;
      break;
    case 'metaDataUrl':
      pattern =
        /^https:\/\/.*(?:well-known\/openid-configuration|federationmetadata\.xml)$/;
      break;
    case 'emailAddress':
      pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      break;
    default:
      throw new Error('Invalid type specified');
  }

  // Test the URL against the pattern
  return pattern.test(url);
};

function isPlainObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export {
  formatData,
  sortAlphabetically,
  upperCaseFirst,
  tooLongString,
  serverError,
  colourStyles,
  last,
  createObjectFromInput,
  decideMode,
  createObjectEdit,
  getRandomId,
  loadingMarkup,
  getChanges,
  getAnswers,
  countFulfills,
  splitArray,
  findCommonElement,
  sortContent,
  includesAll,
  excludeHtml,
  getAnswerWithoutHtml,
  findParentOption,
  isParentFalse,
  isEmptyObject,
  sanitizeHtml,
  testAgainstRegexp,
  isPlainObject,
};
