// Import of components
import { sanitizeHtml } from 'src/shared/function/function';
import tooltip from '../../shared/images/tooltip_icon.png';

// Imoprt of CSS, SASS
import './tooltip.css';

/**
 ** (required) tooltipContent: content to display on hover - string
 ** (required) id: uniq value - string | number
 ** (optinal) addCss: takes left, up. or down(default is right), where to display tooltis - string
 ** (optinal) extraStyle: inline style to override css, write as inline style , ex. {color: '#ccc'}- string
 ** (optinal) image: add own images
 ** (optinal) imageStyle: add style to own images
 ** (optinal) hoverEffect: add style via className to own images (this is added to mkae hover avalable) - string (css class needs to be added in tooltip.css)
 */

const Tooltip = (props: {
  tooltipContent: any | undefined;
  id: string | number | undefined;
  addCss?: any;
  extraStyle?: any;
  image?: any;
  imageStyle?: any;
  styleWithHover?: string;
  contentToDisplay?: any;
  link?: boolean;
}) => {
  // Destruct props
  const {
    tooltipContent,
    id,
    addCss,
    extraStyle,
    image,
    imageStyle,
    styleWithHover,
    contentToDisplay,
    link,
  } = props;

  // Render
  return (
    <span className={`tooltip-container ${link ? 'link-tooltip' : ''}`}>
      {contentToDisplay ? (
        contentToDisplay
      ) : (
        <img
          src={image ? image : tooltip}
          alt="tooltip-icon"
          data-for={id}
          style={imageStyle}
          className={`${styleWithHover}`}
        />
      )}
      <span
        className={`tooltip-content-default ${addCss ? addCss : ''}`}
        style={extraStyle}
      >
        <div
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(tooltipContent) }}
        />
      </span>
    </span>
  );
};

export default Tooltip;
